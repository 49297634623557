import styled, { css } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const GeneralMenu = styled.div`
    position: fixed;
    z-index: 1;
    width: 100%;
    bottom: 0;
    background-color: ${baseTheme.colors.standard};
    border-radius: 20px 20px 0 0 ;
    padding: 15px 20px;
    box-shadow:  4px rgba(0, 0, 0, 0.5);
`;
export const GeneralMenuWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ItemTitle = styled.div`
  text-align: center;
  font-size: 12px;
`;

export const Item = styled.div <{active: boolean,disable?: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  & path {
      fill: ${baseTheme.colors.bodyText}; 
    }
  cursor: pointer;
  ${props => props.active && css`
    ${ItemTitle} {
        color: ${baseTheme.colors.link}; 
    }
    path {
        fill: ${baseTheme.colors.link}; 
    }
  `}
  ${props => props.disable && css`
    opacity: 0.5;
  `}
`;

export const ItemSvg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 24px;
  margin-bottom: 7px;
  & svg{
    width: 24px;
    height: 24px;
  }
`;
