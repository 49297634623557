import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const MainButton = styled.div`
  position: fixed;
  bottom: 90px;
  z-index: 1000;
  border-radius: 10px;
  height: 50px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(${baseTheme.colors.mainButton});
  box-shadow: 5px 5px 5px #c4c4c4;
`;

export const MainButtonTitle = styled.div`
  color: ${baseTheme.colors.standard};
`;