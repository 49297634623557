import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";
import Bg from "../../../Images/BG.png";
import spacecadet from "../../../Images/spacecadet-Photoroom.png"
import { Link } from "react-router-dom";

const levitating = keyframes `
0,100% {
  top:-40px;
}
  50%{
  top:20px;
  }
`;
const levitatingXAxis = keyframes `
0,100% {
  left:5%;
}
  10%{
  left:25%;
  top:40px;
  }
  20%{
  top:0;
  left:45%;
  }
  30%{
  left:65%;
  top:40px;
  }
  40%{
  left:90%;
  top:0;
  }
  50%{
  top:80px;
  left:90%;
  }
  60%{
  left:70%;
  top:0;

  }
  70%{
  left:50%;
  top:40px;
  }
  80%{
    left:30%;
    top:20px;
  }
  90%{
  left:10%;
  top:10px;
  
  }
`;
const rotation1 = keyframes`
from{
transform:rotateY(0);}
to{
transform:rotateY(180deg);
}
`
const rotation2 = keyframes`
from{
transform:rotateY(0);}
to{
transform:rotateY(180deg);
}
`
export const MainMenuImg = styled.div `
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    top:-40px;
    width:200px;
    height:200px;
    background-image:url(${spacecadet});
    background-size:cover;
    color:white;
    animation:${levitating} infinite ease-in-out 4s;
    @media (min-width:760px){
      top:0;
      left:5%;
      
      animation-timing-function: linear;
      animation-name:${levitatingXAxis};
      animation-duration:20s;
      animation-iteration-count:infinite;
      animation-delay: 0s;
      
    }
      
`;
export const MainPage = styled.section`
  position: relative;
`;
export const Section = styled.div`
  background-color: ${baseTheme.colors.headerMain};
  background-image: url(${Bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
`;
export const Container = styled.div`
  margin: 0 auto;
  max-width: 1540px;
  padding: 20px;
  height: 100%;
`;
export const HeaderWrapper = styled.div``;
export const HeaderTopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${baseTheme.colors.borderblure};
  padding: 0px 20px 14px;
  margin-bottom: 20px;
`;
export const Icon = styled.div<{
  position?: boolean;
  isRead?: boolean;
  positionRead?: boolean;
  positionReviews?: boolean;
}>`
  height: 20px;
  width: 25px;
  position: relative;
  cursor: pointer;

  & img {
    height: 25px;
    border-radius: 50%;
    border: 1px solid ${baseTheme.colors.link};
  }

  & svg {
  }

  & path {
    fill: #fff;
  }

  &::after {
    display: none;
  }

  ${(props) =>
    props.position &&
    css`
      & svg {
        position: absolute;
        top: -7px;
        left: -23px;
      }

      &::after {
        display: none;
      }
    `}

  ${(props) =>
    props.positionRead &&
    css`
    & svg{
      position: relative;
      left: 0;
    }
    `}

`;
export const StyledLink = styled(Link)`
  
`;
export const Links = styled.div`
display: flex;
gap:15px;
`
export const IconNotif = styled.div<{ positionRead?: boolean;}>`
  display: block;
  position: absolute;
  left: -8px;
  top: 0px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${baseTheme.colors.link};
  ${(props) =>
    props.positionRead &&
    css`
    left: 66%;
    `}
`;

export const Balance = styled.div`
  position: relative;
  font-size: 16px;
  color: ${baseTheme.colors.textForFon};
`;
export const Slider = styled.div`
  position:relative;  
  display: flex;
  height:200px;
  justify-content:center;
  padding-bottom: 10px;
  & .swiper {
    padding-bottom: 50px;
  }
  & .swiper-slide {
    width: 290px;
  }
  & .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 0.3;
    background-color: transparent;
    border: 3px solid ${baseTheme.colors.standard};
  }
  & .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    opacity: 1;
  }
`;
export const CardWrapper = styled.div`
  position: relative;
`;
export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 290px;
  height: 176px;
  border: 1px solid ${baseTheme.colors.cardColor};
  border-radius: 20px;
  padding: 20px;
  color: ${baseTheme.colors.standard};
  cursor: pointer;
  & svg {
    width: 120px;
    height: 120px;
    fill: ${baseTheme.colors.cardColor};
    opacity: 0.3;
  }
`;
export const CardGradient = styled.div`
  width: 290px;
  height: 176px;
  border: 1px solid ${baseTheme.colors.cardColor};
  border-radius: 20px;
  filter: blur(10px);
  border-radius: 20px;
  border: 1px solid #fff;
`;
export const HistoryWrapper = styled.div`
  padding-bottom:50px;
  `;
export const HistoryWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 14px;
`;
export const HistoryTitle = styled.h4`
  font-size: 20px;
`;
export const HistoryButton = styled.div`
  font-size: 16px;
  line-height: 130%;
  color: ${baseTheme.colors.link};
  cursor: pointer;
`;

const loading = keyframes`
  0% {
    background-color: #eee; 
  }
  100% {
    background-color: #ddd; 
  }
`;

export const SkeletonWrapper = styled.div`
  border: 1px solid ${baseTheme.colors.cardColor};
  border-radius: 20px;
  position: relative;
  width: 290px;
  height: 176px;
  border-radius: 20px;
  background-color: transparent;
`;
export const SkeletonWrapperHistory = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  margin-bottom: 10px;
  background: ${baseTheme.colors.standard};
  border-radius: 10px;
  gap: 14px;
  padding: 15px 10px;
  box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
`;

export const SkeletonElement = styled.div`
  position: absolute;
  background-color: #eee;
  border-radius: 4px;
  animation: ${loading} 1s infinite alternate;
  &.balanceAll {
    width: 60px;
    height: 20px;
    right: 10px;
    top: -10px;
    transform: translateX(50%);
  }
  &.logo {
    top: 20px;
    left: 20px;
    width: 50px;
    height: 20px;
  }
  &.validity {
    top: 20px;
    left: 80px;
    width: 60px;
    height: 20px;
  }
  &.number {
    top: 60px;
    left: 20px;
    width: 250px;
    height: 20px;
  }
  &.balance {
    top: 110px;
    left: 20px;
    width: 50px;
    height: 15px;
  }
  &.csv {
    top: 110px;
    right: 20px;
    width: 30px;
    height: 14px;
  }
  &.balanceNum {
    top: 128px;
    left: 20px;
    width: 100px;
    height: 20px;
  }
  &.csvNum {
    top: 125px;
    right: 20px;
    width: 40px;
    height: 14px;
  }
  &.iconHistory {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0;
    animation: none;
    background-color: #e0e0e0;
  }

  &.titleHistory {
    width: 70%;
    height: 19px;
    left: 52px;
  }

  &.subtitleHistory {
    width: 50%;
    height: 12px;
    bottom: 15px;
    left: 52px;
  }

  &.amountHistory {
    width: 12%;
    height: 20px;
    right: 10px;
    bottom: 10px;
  }
`;
