import React from 'react';
import * as styled from './Manual.style';

export const Manual = ({ Manual }) => {
  const isString = typeof Manual === 'string';

  const isArray = Array.isArray(Manual) && Manual.length >= 2;

  return (
    <>
      {isString && (
        <styled.TexteInfo>
          {Manual}
        </styled.TexteInfo>
      )}
      {isArray && (
        <styled.TexteInfo>
          {Manual[0]}: {Manual[1]}
        </styled.TexteInfo>
      )}
    </>
  );
};