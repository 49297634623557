import React from "react";
import {useToast} from "../useToast/ToastContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as styled from "./MainMenu.style";
import {MainMenuData} from "../../Static/MainMenuData"
import { Link } from "react-router-dom";

export const MainMenu = () => {

    const showToast = useToast();

    return (
        <styled.MainMenu>
            <styled.MenuWrapper>
                {MainMenuData.map(({ id, icon: Icon, text, color, link }) => (
                        <Link to={`${link}`} key={`${id}-MenuItem`}>
                            <styled.MenuItem disable={false}>
                                <styled.ItemSvg color={color}>
                                    <Icon/>
                                </styled.ItemSvg>
                                <styled.ItemTitle>{text}</styled.ItemTitle>
                            </styled.MenuItem>
                        </Link>
                ))}
            </styled.MenuWrapper>
        </styled.MainMenu>
    );
};