import React, { useCallback, useState } from "react";
import * as styled from "./Recharge.style";
import { Back } from "../../Back";
import { Title } from "../../Title";
import { Subtitle } from "../../Subtitle";
import { currencyData } from "../../../Static/SelectedCurrencyData";
import { SwiperSlide, Swiper } from "swiper/react";
import { Button } from "../../Button";
import { BalanceInput } from "../../BalanceInput";
import { CardSlide } from "../../CardSlide";
import { MainButton } from "../../MainButton";
import { ReactComponent as Question } from "src/Svg/Flag/undefindedFlag.svg";
import { DebitingSection } from "src/Components/DebitingSection";

export const RechargeCard = () => {
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCard, setSelectedCard] = useState();
  const [currentSymbol, setCurrentSymbol] = useState(Question);
  const [amountValue, setAmountValue] = useState(0);
  const [toggled, setToggled] = useState(false);
  const [title, setTitle] = useState("None");
  const [subTitle, setSubTitle] = useState("None");

  function handleSelectedCard(currency) {
    if (!currency.disabled) {
      setSelectedCard(currency.code);
    }
  }

  const handleBalanceChange = (event) => {
    setAmountValue(event.target.value);
  };

  function handleCurrencyClick(currency) {
    if (!currency.disabled) {
      setCurrentSymbol(currency.symbol);
      setSelectedCurrency(currency.code);
      setSubTitle(currency.subtitle);
      setTitle(currency.title);
    }
  }
  const HandelToggled = useCallback(() => {
    setToggled((prev) => !prev);
  }, []);


  return (
    <styled.RechargeCard>
      <styled.Container>
        <styled.BackWrapper>
          <Back Title={""} continuation={true} />
        </styled.BackWrapper>
        <Title Title="Пополнение карты" />
        <styled.Wrapper>
          {false ? (
            <>
              <styled.Wrapper>
                <styled.SkeletonWrapper>
                  <styled.SkeletonElement className="title" />
                  <styled.SkeletonSwiperWrapper>
                    {Array.from({ length: 4 }, (_, index) => (
                      <React.Fragment key={index}>
                        <styled.SkeletonElement className="swiperItem" />
                      </React.Fragment>
                    ))}
                  </styled.SkeletonSwiperWrapper>
                </styled.SkeletonWrapper>
              </styled.Wrapper>
              <styled.Wrapper>
                <styled.SkeletonElement className="Text" />
              </styled.Wrapper>
              <styled.Wrapper>
                <styled.SkeletonWrapper>
                  <styled.SkeletonElement className="title" />
                  <styled.SkeletonSwiperWrapper>
                    {Array.from({ length: 4 }, (_, index) => (
                      <React.Fragment key={index}>
                        <styled.SkeletonElement className="swiperItem" />
                      </React.Fragment>
                    ))}
                  </styled.SkeletonSwiperWrapper>
                </styled.SkeletonWrapper>
              </styled.Wrapper>
              <styled.Wrapper>
                <styled.SkeletonWrapper>
                  <styled.SkeletonElement className="title" />
                  <styled.SkeletonSwiperWrapper>
                    {Array.from({ length: 2 }, (_, index) => (
                      <styled.SkeletonItem className="cardSwiperItem">
                        <React.Fragment key={index}>
                          <styled.SkeletonElement className="card" />
                          <styled.SkeletonElement className="info" />
                        </React.Fragment>
                      </styled.SkeletonItem>
                    ))}
                  </styled.SkeletonSwiperWrapper>
                </styled.SkeletonWrapper>
              </styled.Wrapper>
            </>
          ) : (
            <>
              <styled.Wrapper>
                <Subtitle Title="Выбор валюты:" />
                <styled.SwiperWrapper>
                  <Swiper spaceBetween={10} slidesPerView={"auto"}>
                    {currencyData
                    .filter((currency) => currency.code !== "Question")
                    .map((currency) => (
                      <SwiperSlide key={currency.code}>
                        <Button
                          Title={currency.title}
                          active={selectedCurrency === currency.code}
                          onClick={() => handleCurrencyClick(currency)}
                          disable={currency.disabled}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </styled.SwiperWrapper>
              </styled.Wrapper>
              <styled.Wrapper>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur.
              </styled.Wrapper>
              <styled.Wrapper>
                <Subtitle Title="Сумма:" />
                <styled.AmountWrapper>
                  <BalanceInput
                    symbol={currentSymbol}
                    name={title}
                    subtitle={subTitle}
                    value={String(amountValue)}
                    onChange={(e) => handleBalanceChange(e)}
                  />
                </styled.AmountWrapper>
              </styled.Wrapper>
              <styled.Wrapper>
                <Subtitle Title="Используемая карта:" />
                <styled.SwiperWrapper>
                  <Swiper spaceBetween={10} slidesPerView={"auto"}>
                    {currencyData.map((currency) => (
                      <SwiperSlide key={currency.code}>
                        <CardSlide
                          onClick={() => handleSelectedCard(currency)}
                          disable={currency.disabled}
                          active={currency.code === selectedCard}
                          firmCard={currency.code}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </styled.SwiperWrapper>
              </styled.Wrapper>
            </>
          )}
          <DebitingSection toggled={toggled} />
        </styled.Wrapper>
        <MainButton title="Пополнить" />
      </styled.Container>
    </styled.RechargeCard>
  );
};
