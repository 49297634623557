import styled, { css } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

export const BalanceInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${baseTheme.colors.standard};
  border-radius: 10px;
  gap : 2px;
  width: 100%;
  box-shadow: 5px 5px 5px rgba(206, 214, 225, 0.3);
  padding-left: 10px;
  & svg{
    border: 1px solid ${baseTheme.colors.borderFlag};
    border-radius: 50%;
  }
`;

export const BalanceBorder = styled.div`
  border-right: 1px solid  ${baseTheme.colors.borderCurrency};
  height:45px;
  margin: 5px;
  opacity: 0.5;
`;
export const Symbol = styled.span`
  color: ${baseTheme.colors.link};
  font-size: 40px;
  font-weight: 500;
  position: absolute;
  width: 35px;
  height: 35px;
  left: 10px;

  svg {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Input = styled.input<{ disabled: boolean }>`
  position: relative;
  background: ${baseTheme.colors.standard};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
  font-size: 40px;
  font-weight: 500;
  line-height: 30.8px;
  color: ${baseTheme.colors.bodyText};
  text-align: left;
  /* box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow}; */
  width: 200px;
  height: 59px;
  padding: 14px 0px 14px 0px;
  gap: 10px;
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
    `}
`;
