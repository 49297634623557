export const baseTheme = {
    colors: {
        grad: "180deg, rgba(255,88,135,1) 0%, rgba(209,88,122,1) 100%",
        gradCard: "45deg, rgb(46 49 128) 37%, rgba(232, 138, 164, 1) 74%, rgba(32, 141, 224, 1) 96%",
        standard: '#fff',
        mainButton: '45deg, #97DAFF 0%, #16579D 100%',
        headerMain: "#161966",
        main: "#F1F5FD",
        text: "#2C3B82;",
        textForFon: "#fff",
        bodyText: "#2C3B82;",
        link:"#FF5887",
        introduction:"#3EB290",
        borderblure:"rgba(206, 214, 225, 0.3 )",
        border:"rgba(206, 214, 225 )",
        borderCurrency:"rgba(44, 59, 130, 0.5)",
        borderFlag:"rgba(44, 59, 130, 0.2)",
        cardColor: "#AEB1FF",
        boxShadow: "rgba(206, 214, 225, 0.3)",
        //boxShadow: "rgba(27, 29, 77, 0.5)",
        operation:'#3EB290',
        receiving:'#55ACEE',
        replenish:'#EECC55',
        rent:'#FF8A71',
        privacyTitle: "#2C3B82",
        privacyText: "#2C3B82"
    },
    with:{
        desktop: "1840px",
    },
    media: {
        extraLarge: '1880px',
        large: '1599px',
        medium: '1279px',
      },
}