import React from "react";
import * as styled from "./ReceiptScreen.style";
import { ReactComponent as Close } from "../../Svg/close.svg";

interface ReceiptScreenProps {
    img: string | null;
    show: boolean;
    onClose: () => void; 
}

export const ReceiptScreen: React.FC<ReceiptScreenProps> = ({ img, show, onClose }) => {
    return ( 
        <styled.ReceiptScreen>
          <styled.ImgWrapper show={show}>
            <styled.Close onClick={onClose}> 
                <Close />
            </styled.Close>
            <img src={img} alt="Ticket" />
          </styled.ImgWrapper>
        </styled.ReceiptScreen>
    );
};