export const jsonData = [
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 1"
  },
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 2"
  },
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 3"
  },
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 4"
  },
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 5"
  },
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 6"
  },
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 7"
  },
  {
    image: "https://via.placeholder.com/300",
    title: "Карточка 8"
  }
];

export default jsonData;
