import styled from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 130px 20px;
  background-color: none;
  background: none;
  position: relative;
`;

export const BackWrapper = styled.div`
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-rows: repeat(12, 1fr); */
  grid-gap: 14px;


  /* @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  } */
`;
