import styled from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

interface AccordionHeaderProps {
  isOpen: boolean;
}

interface AccordionContentProps {
  isOpen: boolean;
}

interface ArrowIconProps {
  isOpen: boolean;
}

export const AccordionItem = styled.div`
  margin-bottom: 10px;
`;

export const AccordionHeader = styled.div<AccordionHeaderProps>`
  position: relative;
  background: #ffffff;
  cursor: pointer;
  min-height: 50px;
  padding: 20px 30px 20px 20px;
  border-radius: 10px;
  border-bottom: 1px solid #ccc;
  color: ${(props) => (props.isOpen ? baseTheme.colors.link : baseTheme.colors.text)};
  border-bottom-left-radius: ${(props) => (props.isOpen ? "0px" : "10px")};
  border-bottom-right-radius: ${(props) => (props.isOpen ? "0px" : "10px")};
  box-shadow: ${(props) => (props.isOpen ? `0px -1px 5px 5px ${baseTheme.colors.boxShadow}` : `0px 0px 5px 5px ${baseTheme.colors.boxShadow}`)};
  font-size: 20px;
  overflow-wrap: break-word;
`;

export const ArrowIcon = styled.img<ArrowIconProps>`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) ${(props) => (props.isOpen ? "rotate(90deg)" : "rotate(270deg)")};
  width: 8px;
  height: 15px;
  transition: transform 0.3s ease;
  filter: ${(props) => (props.isOpen ? `invert(44%) sepia(77%) saturate(2318%) hue-rotate(303deg) brightness(101%) contrast(101%)` : "none")};
`;

export const AccordionContent = styled.div<AccordionContentProps>`
  background-color: #ffffff;
  color: ${baseTheme.colors.bodyText};
  padding: 20px;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 5px 5px ${baseTheme.colors.boxShadow};

  font-size: 16px;
  overflow-wrap: break-word;
`;
