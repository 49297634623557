import styled, { css } from "styled-components";
import { baseTheme } from "../../../../Style/baseTheme";
import Bg from "../../../Images/BG.png";

export const ExchangeCurrencyWrapper = styled.div`
`;
export const ExchangeCurrency = styled.div`
    display: grid;
    grid-template-columns:  60% 1fr 1fr;
    color: ${baseTheme.colors.bodyText};
    padding-bottom: 10px;
    margin: 0px;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
    margin-bottom: 10px;
`;
export const TitleDollars = styled.div`
    color: ${baseTheme.colors.text};
    line-height: 160%;
    font-size: 21px;
`;
export const TitleInDollars = styled.div`
    color: ${baseTheme.colors.text};
    line-height: 160%;
    font-size: 21px;
`;