import React from "react";
import * as styled from "./Button.style";


interface ButtonProps {
    Svg?: React.ElementType;
    Title: string;
    active?: boolean;
    disable?: boolean;
    color?: boolean;
    onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({ color, Svg, Title, active, disable, onClick }) => {
  return (
    <styled.Button color={color} disable={disable} onClick={onClick} active={active}>
      {Svg &&
      <styled.Img>
         <Svg />
      </styled.Img>}
      {Title ? <styled.Title>
        {Title}
      </styled.Title>
      : null}
    </styled.Button>
  );
};