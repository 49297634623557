import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";
const loading = keyframes`
  0% {
    background-color: #eee; 
  }
  100% {
    background-color: #ddd; 
  }
`;

export const Container = styled.div`
  position: relative; 
`;
export const ExchangeWrapper = styled.div`
  padding:20px 20px 0;
  height: calc(100vh + 90px);
`;
export const ExchangeWrap = styled.div`
`;
export const BackWrapper = styled.div`
margin-bottom: 8px;
`;
export const ExchangeTitle = styled.h1`
margin-bottom: 10px;
`;
export const ExchangeSubitleWrapp = styled.div`
    display: grid;
    grid-template-columns: 60% 1fr 1fr;
    color: ${baseTheme.colors.bodyText};
    padding: 0px 0px 10px;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
    margin-bottom: 10px;
`;
export const TitleWrapp = styled.div`
    color: ${baseTheme.colors.bodyText};
    line-height: 160%;
`;
export const Title = styled.div`
    color: ${baseTheme.colors.bodyText};
    line-height: 160%;
`;
export const TitleInDollars = styled.div`
    color: ${baseTheme.colors.bodyText};
    line-height: 160%;
`;
export const Flag = styled.div`
`;
export const ExchangeCurrency = styled.div`
    display: grid;
    grid-template-columns: 60% 1fr 1fr;
    color: ${baseTheme.colors.bodyText};
    padding: 0 20px 10px;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
    margin-bottom: 10px;
`;
export const SkeletonWrapperExchange = styled.div`
    position: relative;
    width: 100%;
    height: 67px;
    padding: 0 20px 10px;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
    margin-bottom: 10px;
`;
export const WrapperMainButton = styled.div`
`;

export const SkeletonElement = styled.div`
  position: absolute;
  background-color: #eee; 
  border-radius: 4px;
  animation: ${loading} 1s infinite alternate; 
  &.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0;
    animation: none;
    background-color: #e0e0e0;
    left: 0;
  }

  &.title {
    width: 70%;
    height: 25px;
    left: 52px;
    top: 5px;
  }

  &.subtitle {
    width: 50%;
    height: 12px;
    bottom: 15px;
    left: 52px;
  }

  &.amount {
    width: 12%;
    height: 25px;
    right: 10px;
    top: 5px;
  }
`;
