import { baseTheme } from "src/Style/baseTheme";
import styled, { css } from "styled-components";

export const TitleWrap = styled.div<{width: boolean}>`
  display: flex;
  gap: 10px;
  align-items : center;
  position: relative;
  ${(props) => props.width && css`
    width : 100px;
  `}
`;

export const Flag = styled.div`
  width: 40px;
  height: 40px;
  /* transform: translateY(6px); */
  & svg {
    width: 40px;
    height: 40px;
  }
`;

export const OriginWrapp = styled.div``;
export const Title = styled.div`
  color: ${baseTheme.colors.text};
  line-height: 160%;
  font-size: 21px;
  
`;
export const Origin = styled.div`
  margin-top: -5px;
  line-height: 160%;
  font-size: 12px;
  color: ${baseTheme.colors.bodyText};
`;
