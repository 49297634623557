import styled, { css, keyframes } from "styled-components";
import spacecadet from '../../../Images/spacecadet-Photoroom.png'
import bg from '../../../Images/onlymobile.jpg'

export const Container = styled.div`
position: relative;
display: flex;
background-image: url(${bg});
background-size: cover;
width: 100vw;
flex-direction: column;
justify-content: flex-end;
padding:0px 10px 100px 10px;
align-items: center;
text-align: center;
height: 100vh;
color: white;
gap:40px;
 h1{
    font-weight: 400;
    color: white;
    -webkit-text-stroke: 1px #000;
 span{
    font-weight: 700;
 }
 }
 @media (min-width:490px){
    z-index: 10;
 };
 @media (min-width: 760px) {
    gap:100px;
    padding-top: 110px;
    h1{
        font-size: 30px;
    };
}
@media (min-width: 1024px) {
background-position: top;
background-repeat: no-repeat;
}
`
const levitating = keyframes `
    0%{
        top:0;
        left:0;
        transform: translate(0) translateY(0);
    }
    25% {
        top: 100%;
        left: 0;
        transform: translateX(0) translateY(-100%);
    }
    50% {
        top: 100%;
        left: 100%;
        transform: translateX(-100%) translateY(-100%);
    }
    75% {
        top: 0;
        left: 100%;
        transform: translateX(-100%) translateY(0);
    }
    100% {
        top: 0;
        left: 0;
        transform: translateX(0) translateY(0);
    }
`;
export const Image = styled.div`
    position:absolute;
    top:0;
    left:50%;
    bottom: 0;
    right:0;
    z-index: -10;
    transform: translateX(-50%);
    width:200px;
    height:200px;
    background-image:url(${spacecadet});
    background-size:cover;
    animation: ${levitating} infinite linear 30s;
`