import React from "react";
import * as styled from "./AmountDisplay.style";
import { Currency } from "../Currency";

interface AmountDisplayProps {
  Symbol?;
  Number?: string | number;
  title?: string;
  label?: string;
}

export const AmountDisplay: React.FC<AmountDisplayProps> = ({
  Symbol,
  Number,
  title,
  label,
}) => {
  return (
    <styled.AmountWrap>
      {Symbol && title && label ? (
        <Currency label={label} width={true} title={title} FlagIcon={Symbol} />
      ) : null}
      <styled.BalanceBorder />
      {Number}
    </styled.AmountWrap>
  );
};
