import styled, { keyframes } from "styled-components";
import { baseTheme } from "src/Style/baseTheme";

export const Container = styled.div`
    //h-screen w-screen z-10 overflow-hidden fixed bottom-0 bg-black/50
    height: 100vh;
    width:100vw;
    z-index: 5;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
`
//text-white absolute px-10 text-center rounded-t-[20px] 
//bottom-0 h-auto min-h-[230px] w-full flex flex-col justify-center item-center gap-2 bg-slate-900
export const slide_out = keyframes`
    from {}
` 
export const Inner = styled.div`
    position: absolute;
    bottom: 0;
    height: auto;
    min-height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: ${baseTheme.colors.bodyText};
    text-align: start;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: white;
    padding: 0 30px 0 30px;
    h1{
        padding:0;
        margin:0;
        width: 100%;
        color:white;
    }
    p{
        font-size: 16px;
        width: 100%;
        
        color:white;
    }

`