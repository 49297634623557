import * as styled from "./TgAuth.style";
import TelegramModal from "src/Components/TelegramModal/TelegramModal";


export const TelegramAuthPage = () => {

    return (
    <styled.Container>
        <h1>
            <span>
            ROCKET APP
            </span>
        </h1>
        <TelegramModal/>
            <styled.ImageSpaceCadet/>
            <styled.ImageMoonshard/>
            <styled.Cicrle/>
            <styled.Cicrle2/>
    </styled.Container>
  )
}
