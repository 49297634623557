import React, { useCallback, useEffect, useState } from "react";
import * as styled from "./ButtonsFilter.style";
import { Button } from "../Button/Button";
import { UrlHistoryFilters } from "../../Static/Url";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import axios from "axios";

export const ButtonsFilter = ({ setOrder }) => {
    const [apiData, setApiData] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [isLoading, setIsLoading] = useState(true); 

    
    const authToken = localStorage.getItem('authToken');

    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(`${UrlHistoryFilters}`,
            {headers: {
              'Authorization': `Bearer ${authToken}`
            }});
            setApiData(response.data); 
            setIsLoading(false); 
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
            setIsLoading(false); 
        }
    }, []);
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    const handleFilterClick = (title) => {
        const isActive = activeFilters.includes(title);
        const newActiveFilters = isActive ? activeFilters.filter(filter => filter !== title) : [...activeFilters, title];
        setActiveFilters(newActiveFilters);
        setOrder(newActiveFilters.join(','));
    };

    return (
        <styled.ButtonsFilter>
            {isLoading ? (
                <styled.SkeletonWrapper>
                    <styled.SkeletonElement className="button" />
                    <styled.SkeletonElement className="button" />
                    <styled.SkeletonElement className="button" />
                    <styled.SkeletonElement className="button" />
                </styled.SkeletonWrapper>
            ) : (
                <Swiper spaceBetween={10} slidesPerView={'auto'}>
                    {apiData.map((title, index) => (
                        <SwiperSlide key={index} onClick={() => handleFilterClick(title)}>
                            <Button 
                                active={activeFilters.includes(title)}
                                Title={title}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </styled.ButtonsFilter>
    );
};