import React from 'react'
import * as styled from "./InstructionsRecieving.style";
import { Back } from 'src/Components/Back';
import { Title } from '../../Title/Title';

export const InstructionsRecieving =() => {
  return (
    <styled.Container>
      <styled.BackWrapper>
        <Back Title={""} continuation={true} />
      </styled.BackWrapper>
      <styled.Header>
            Как снять деньги в банкомате без карты
        </styled.Header>
      <styled.Text>
        <p>
                На примере PERMATA (аналогично работает в BCA, MANDIRI). Также можно снимать на кассах Indomaret. Эта функция позволяет снимать наличные в банкомате с помощью телефона, не используя пластиковую карту.
                <br/><br/>Из главного меню выбираем Mobile Cash - PermataBank ATM - вводим свой номер телефона в виде 08XX-XXXX-XXXX - выбираем сумму, которую хотим получить в банкомате - проверяем всю введенную информацию и подтверждаем.
                <br/><br/>Подходим к банкомату Permata - нажимаем там кнопку Mobile Cash - вводим свой номер телефона в виде 08XX-XXXX-XXXX - теперь нужно будет ввести код из SMS, которое придет на телефон - забираем деньги.
                
        </p>
      </styled.Text>
        
    </styled.Container>
  )
}

export default InstructionsRecieving