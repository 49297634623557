import React from "react";
import * as styled from "./CommentInput.style";

interface CommentInputProps {
  comment: string;
  setComment: (comment: string) => void;
  placeholder?: string;
  inputHeight?: number;
}

export const CommentInput: React.FC<CommentInputProps> = ({
  comment,
  setComment,
  placeholder = "Введите комментарий",
  inputHeight = 100,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  return (
    <styled.CommentInputContainer>
      <styled.CommentTextArea
        placeholder={placeholder}
        value={comment}
        onChange={handleChange}
        style={{ height: inputHeight }}
      />
    </styled.CommentInputContainer>
  );
};

