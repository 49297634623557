import { baseTheme } from "src/Style/baseTheme";
import styled, { css, keyframes } from "styled-components";


const loading = keyframes`
  0% {
    background-color: #eee; 
  }
  100% {
    background-color: #ddd; 
  }
`;

export const RechargeCard = styled.div`
  background-color: ${baseTheme.colors.main};
`;

export const BackWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Container = styled.div`
  padding: 20px 20px 130px;
  position: relative;
`;

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const MyRate = styled.div`
  color: #4c4c60;
  font-size: 12px;
`;

export const SwiperWrapper = styled.div`
  & .swiper-slide {
    max-width: max-content;
  }
`;

export const SkeletonWrapper = styled.div`
  padding-top: 40px;
  position: relative;
`;

export const SkeletonSwiperWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  height: 70px;
  gap: 10px;
  & .swiperItem {
    position: relative;
    width: 90px;
    height: 30px;
  }
`;

export const SkeletonItem = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  margin-bottom: 10px;
  background: ${baseTheme.colors.standard};
  border-radius: 10px;
  gap: 14px;
  padding: 15px 10px;
  box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};

  &.cardSwiperItem {
    position: absolute;
    width: 250px;
    
    top: 0;
    &:first-child {
      left: 0;
    }
    &:last-child {
      
      right: -170px;
    }
  }
`;

export const SkeletonElement = styled.div`
  position: absolute;
  background-color: #eee;
  overflow: hidden;
  border-radius: 4px;
  animation: ${loading} 1s infinite alternate;
  &.title {
    width: 70%;
    height: 24px;
    margin-bottom: 10px;
    top: 0;
  }

  &.card {
    top: 7px;
    left: 10px;
    width: 80px;
    height: 50px;
  }
  &.info {
    top: 7px;
    left: 100px;
    width: 130px;
    height: 50px;
  }
  & .cardSwiperItem {
    overflow: hidden;
  }

  &.listItem {
    width: 90%;
    height: 20px;
    margin-bottom: 5px;
  }

  &.Text {
    width: 100%;
    height: 152px;
    position: relative;
  }
`;
