import { baseTheme } from "src/Style/baseTheme";
import styled, { keyframes } from "styled-components";

const loading = keyframes`
  0% {
    background-color: #eee; 
  }
  100% {
    background-color: #ddd; 
  }
`;

export const SkeletonElement = styled.div`
  position: absolute;
  background-color: #eee;
  border-radius: 4px;
  animation: ${loading} 1s infinite alternate;
  &.title {
    width: 70%;
    height: 24px;
    margin-bottom: 10px;
    top: 0;
  }

  &.listItem {
    width: 90%;
    height: 20px;
    margin-bottom: 5px;
  }

  &.Text {
    width: 100%;
    height: 210px;
    position: relative;
  }
`;
export const Amount = styled.div`
  font-size: 40px;
  display: flex;
  width: max-content;
  gap: 6px;
  padding: 8px 50px 8px 20px;
  background: ${baseTheme.colors.standard};
  border-radius: 10px;
  .symbol {
    color: ${baseTheme.colors.link};
  }
`;

export const AmountNumber = styled.div`
  font-size: 40px;
  .symbol {
    color: ${baseTheme.colors.link};
  }
`;

export const SkeletonWrapper = styled.div`
  padding-top: 40px;
  position: relative;
`;

export const SkeletonSwiperWrapper = styled.div`
  position: relative;
  display: flex;
  height: 50px;
  gap: 10px;
  & .swiperItem {
    position: relative;
    width: 90px;
    height: 30px;
  }
`;

export const Container = styled.div`
  padding: 20px 20px 130px;
  position: relative;
`;
export const NewCardTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 25px;
`;
export const NewCardButtonWrapper = styled.div`
  .swiper-slide {
    max-width: max-content;
  }
`;

export const NewCardPaymentSystemButton = styled.div`
  .swiper {
    z-index: unset;
  }
  & .swiper-slide {
    & > div > div {
      width: 100%;
    }

    width: max-content;
    svg {
      width: 50px;
    }
    #Maestro_Card {
      transform: scale(1.2);
    }
    #master_card {
      transform: scale(1.2);
    }
    #visa {
      transform: scale(1.2);
    }
  }
`;

export const BackWrapper = styled.div`
  margin-bottom: 10px;
`;
export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const NewCardCard = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;

  margin-bottom: 20px;
`;
export const NewCardText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
`;
export const SmsCode = styled.div`
  margin-bottom: 10px;
`;

export const SmsCodeButton = styled.div`
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const NewCardSwiper = styled.div`
  margin-bottom: 10px;
  & .swiper-wrapper {
    height: 42px;
  }
  & .swiper-slide {
    width: max-content;
  }
`;
