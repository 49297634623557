import React, { ChangeEvent } from "react";
import * as styled from "./BalanceInput.style";
import { BalanceInputProps } from "./BalanceInput.types";
import { Currency } from "../Currency";

export const BalanceInput: React.FC<BalanceInputProps> = ({
  value,
  onChange,
  symbol,
  name,
  subtitle,
  disabled,
  onFocus,
  onBlur,
}) => {
  console.log(subtitle)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const addSpaces = (num: string | number) => {
      if (!num) return num;
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    };
    
    let inputValue = event.target.value.replace(/\D/g, "");
    console.log(value)
    inputValue = Number.isNaN(parseInt(inputValue))
      ? "0"
      : parseInt(inputValue).toString();
    const possibleLength = subtitle !== "Rupiyah" ? 5 : 8
    
    if ((inputValue.length <= possibleLength )) {
      onChange({
        target: { value: `${subtitle!=="Rupiyah" ? addSpaces(inputValue): inputValue}` },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <styled.BalanceInputWrapper>
      {symbol && name && subtitle ? (
        <Currency FlagIcon={symbol} title={name} label={subtitle}  width={true}/>
      ) : null}
    <styled.BalanceBorder />
      <styled.Input
        type="text"
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        pattern="\d*"
        inputMode="numeric"
        autoComplete="off"
      />
    </styled.BalanceInputWrapper>
  );
};
