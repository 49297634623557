import styled from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

export const CommentInputContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const CommentTextArea = styled.textarea`
  padding: 20px;
  border-radius: 8px;
  border: none;
  width: 100%;
  min-height: 150px;
  resize: vertical;
  line-height: 1.5;
  box-shadow: 0 0 5px ${baseTheme.colors.boxShadow};
  outline: none;
  color: ${baseTheme.colors.bodyText};
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  &::placeholder {
    color: ${baseTheme.colors.bodyText};
  }
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
`;

