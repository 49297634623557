import { ReactComponent as Dollar } from "../Svg/GeneralMenu/order.svg";
import { ReactComponent as Repeat } from "../Svg/repeat.svg";
import { ReactComponent as Wallet } from "../Svg/GeneralMenu/wallet 1.svg";
import { ReactComponent as Handshake } from "../Svg/handshake.svg";
import { baseTheme } from "../Style/baseTheme";

export const MainMenuData = [
    {
        id:0,
        icon: Handshake,
        text:'Все операции',
        color: `${baseTheme.colors.operation}`,
        link:'/history',
    },
    {
        id:1,
        icon:Repeat,
        text:'Получение наличных',
        color: `${baseTheme.colors.receiving}`,
        link:'/exchange',
    },
    {
        id:2,
        icon:Dollar,
        text:'Оплатим за вас',
        color: '#694982',
        link:'/order_product',
    },
    {
        id:3,
        icon:Wallet,
        text:'Частые вопросы',
        color: `${baseTheme.colors.replenish}`,
        link:'/faq_page',
    },
]