import { useEffect } from "react";

import * as styled from "./Language.style";
import {Back} from "../../Back/Back";
import LanguageList from "./LanguageList/LanguageList";

const Language = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);

    // Функция обратного вызова при загрузке скрипта Google Translate
    (window as any).googleTranslateElementInit = () => {
      new (window as any).google.translate.TranslateElement(
        {
          pageLanguage: "ru",
          layout: (window as any).google.translate.TranslateElement.InlineLayout
            .SIMPLE,
          autoDisplay: false,
        },
        "google_translate_element"
      );
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <><div style={{padding:'20px 0 0 20px'}}>
      <Back Title="" continuation={false}/>
    </div>
      <styled.Container>
        <styled.Title>
            <h1>Выбор языка:</h1>
        </styled.Title>
        <LanguageList />
      </styled.Container>
    </>
  );
};

export default Language;
