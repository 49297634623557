import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 130px 20px;
  position: relative;
`;

export const BackWrapper = styled.div``;

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const MainButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
`;

export const BalanceInput = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

export const RateDisplay = styled.div`
  align-items: center;
  gap: 14px;
`;

export const BalanceInputDisplay = styled.div`
  margin-bottom: 6px;
`;

export const PaymentMethod = styled.div<{ selected: boolean }>`
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  span {
    padding-left: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Radio = styled.div<{ selected: boolean }>`
  width: 24px;
  height: 24px;
  border: 2px solid #ced6e1;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) =>
    selected ? `${baseTheme.colors.standard}` : "transparent"};

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ selected }) =>
      selected ? `${baseTheme.colors.link}` : "transparent"};
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 14px 20px 14px 50px;
  gap: 10px;
  border-radius: 10px;
  font-size: 22px;
  opacity: 1;
  background: ${baseTheme.colors.standard};
  border: none;
  box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${baseTheme.colors.bodyText};
  }
`;

export const CardImage = styled.img`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 20px;
`;
