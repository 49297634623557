import { ReactComponent as Visa } from "../Svg/CardFirm/visa_blue.svg";
import { ReactComponent as Maestro } from "../Svg/CardFirm/maestroRent.svg";
import { ReactComponent as MasterCard } from "../Svg/CardFirm/mastercard_payment_method_icon_142750.svg";


export const filmCards = [
    {
      title: "VISA",
      code: "VISA",
      active: true,
      disabled: false,
      image : Visa,
    },
    {
      title: "MASTERCARD",
      code: "MASTERCARD",
      active: false,
      disabled: false,
      image : MasterCard, 
    },
    {
      title: "MAESTRO",
      code: "MAESTRO",
      active: false,
      disabled: false,
      image : Maestro, 
    }
  ];