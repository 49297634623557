import styled, { css } from "styled-components";
import back from "src/Svg/CardFirm/card_skeleton.svg";
import { baseTheme } from "src/Style/baseTheme";

export const Button = styled.div<{
  active: boolean;
  disable: boolean;
}>`
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  background: ${baseTheme.colors.standard};
  border-radius: 10px;
  align-items: center;
  width: max-content;

  ${(props) =>
    props.active &&
    css`
      ${Card} {
        position: relative;
        min-height: 46px;
        min-width: 72px;
        border-radius: 5px;
      }
      background: ${baseTheme.colors.link};
      ${Img} {
        color: ${baseTheme.colors.standard};
      }
      ${Info} {
        div {
          color: ${baseTheme.colors.standard}!important;
        }
      }
    `}
  ${(props) =>
    props.disable &&
    css`
      opacity: 0.5;
    `}
  box-shadow: 2px 2px 2px ${baseTheme.colors.boxShadow};
`;

export const Card = styled.div`
  position: relative;
  min-height: 46px;
  min-width: 72px;
  background: url(${back});
`;

export const Img = styled.div`
  width: 30px;
  height: 20px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 10px;
  & svg {
    width: 35px;
    height: 20px;
  }
`;

export const Info = styled.div`
  padding: 5px 0px;
`;

export const CardNumber = styled.div`
  color: #4c4c60;
`;

export const CardBalance = styled.div`
  color: #1b1d4d;
`;
