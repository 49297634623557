import React, { useEffect, useState } from "react";
import axios from "axios";
import * as styled from "./Reviews.style";
import { Back } from "src/Components/Back";
import { Title } from "src/Components/Title";
import { MainButton } from "src/Components/MainButton";
import { ReviewItem } from "src/Components/ReviewItem";
import { Link } from "react-router-dom";

export const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  
  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('https://unofficial-trust-pilot.p.rapidapi.com/business-units/v2/get-reviews?identifyingName=rocket.terrapay.online&page=1', {
          headers: {
            'x-rapidapi-key': '22f05646edmsh6db9b1c43194505p150250jsn7c1f65a0c00e',
            'x-rapidapi-host': 'unofficial-trust-pilot.p.rapidapi.com',
          }
        });
        
        setReviews(response.data.reviews || []);
      } catch (error) {
        console.error("Ошибка при получении отзывов:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);
  return (
    <styled.Section>
      <styled.Container>
        <styled.BackWrapper>
          <Back continuation={false} Title={''} />
        </styled.BackWrapper>
        <Title Title={"Отзывы"} />
        {
          reviews.length > 0 ? (
            <styled.PrivacyUl>
              {reviews.map((review, index) => (
                <styled.PrivacyLi key={index}>
                  <ReviewItem review={review}/>
                </styled.PrivacyLi>
              ))}
            </styled.PrivacyUl>
          ) : (
            <styled.SkeletonLoader />
          )
        }
        <Link to="https://www.trustpilot.com/review/rocket.terrapay.online">
          <MainButton title={"Оставить отзыв"} />
        </Link>
      </styled.Container>
    </styled.Section>
  );
};