import { ReactComponent as Euro } from "../Svg/Flag/european.svg";
import { ReactComponent as Rub } from "../Svg/Flag/russia.svg";
import { ReactComponent as Dollar } from "../Svg/Flag/usa.svg";
import { ReactComponent as Usdt } from "../Svg/Flag/usdt.svg";
import { ReactComponent as Question  } from "../Svg/CurrencySymbol/Question.svg";
import {ReactComponent as Idr} from "../Svg/Flag/indonesia.svg"


export const currencyData = [
    {
      title: "RUB",
      subtitle: "Russian ruble",
      code: "RUB",
      symbol :  Rub,
      active: false,
      disabled: false,
    },
    // {
    //   title: "USD",
    //   subtitle: "US",
    //   code: "USD",
    //   symbol : Dollar,
    //   active: false,
    //   disabled: false,
    // },
    // {
    //   title: "EUR",
    //   subtitle: "Euro",
    //   code: "EUR",
    //   symbol : Euro,
    //   active: false,
    //   disabled: false,
    // },
    // {
    //   title: "ЮMONEY",
    //   subtitle: "Russian ruble",
    //   code: "RUB",
    //   symbol : Rub,
    //   active: false,
    //   disabled: false,
    // },
    {
      title: "USDT",
      subtitle: "TRC-20",
      code: "USDT",
      symbol : Usdt,
      active: false,
      disabled: false,
    },
    {
      title: "Question",
      code: "Question",
      symbol : Question,
      active: false,
      disabled: false,
    }
  ];

  export const flagData = [
    {
      title: "RUB",
      subtitle: "Russian ruble",
      code: "RUB",
      symbol :  Rub,
      active: false,
      disabled: false,
    },
    {
      title: "USD",
      subtitle: "US",
      code: "USD",
      symbol : Dollar,
      active: false,
      disabled: false,
    },
    {
      title: "EUR",
      subtitle: "Euro",
      code: "EUR",
      symbol : Euro,
      active: false,
      disabled: false,
    },
    {
      title: "IDR",
      subtitle: "Rupiyah",
      code: "IDR",
      symbol : Idr,
      active: false,
      disabled: false,
    },
    {
      title: "USDT",
      subtitle: "TRC-20",
      code: "USDT",
      symbol : Usdt,
      active: false,
      disabled: false,
    },
    {
      title: "ЮMONEY",
      subtitle: "Russian ruble",
      code: "YOO",
      symbol : Rub,
      active: false,
      disabled: true,
    },
    {
      title: "Question",
      code: "Question",
      symbol : Question,
      active: false,
      disabled: false,
    }
  ];