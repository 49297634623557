import React, { useEffect, useState } from "react";
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import style from "./LanguageList.module.css";
import DE from "../../../../Svg/country/DE.svg";
import FR from "../../../../Svg/country/FR.svg";
import GB from "../../../../Svg/country/GB.svg";
import IT from "../../../../Svg/country/IT.svg";
import RU from "../../../../Svg/country/ru.svg";
import UA from "../../../../Svg/country/ua.svg";
import BE from "../../../../Svg/country/by.svg";
import TR from "../../../../Svg/country/tr.svg";
import PL from "../../../../Svg/country/pl.svg";
import JA from "../../../../Svg/country/jp.svg";
// import { setSelectedLanguage } from '../../../../../store/languageSlice';

const LanguageList: React.FC = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lan,setLan] = useState('')
  const cookie = document.cookie
  console.log('Cookie =' ,cookie)
  useEffect(() => {
    const TranslateSetCookie = (code: string) => {
      document.cookie = `googtrans=/auto/${code};path=/;domain=${document.domain}`;
    };
    const handleLanguageChange = (language: string) => {
      TranslateSetCookie(language);
      console.log(language)
      // dispatch(setSelectedLanguage(language));
      // Set a flag in localStorage before reloading
      localStorage.setItem('navigateAfterReload', 'true');
      window.location.reload()
    };
    const items = Array.from(document.getElementsByClassName(`${style.LanguageItem}`));
    console.log(items)
    items.forEach((item) => {
      item.addEventListener("click", () => {
        let language = (item as HTMLElement).dataset.lang;
        if (language) {
          handleLanguageChange(language);
        }
      });
    });

    // // Check if the flag is set in localStorage
    if (localStorage.getItem('navigateAfterReload') === 'true') {
      localStorage.removeItem('navigateAfterReload');
      setTimeout(() => {
        navigate('/');
      }, 100); // Adjust the delay as necessary
    }

    return () => {
      items.forEach((item) => {
        item.removeEventListener("click", () => {});
      });
    };
  }, []);

  return (
    <div className={style.LanguageList}>
        <div className={style.LanguageItem} translate="no" data-lang="be">
        <div className={style.img}>
          <img src={BE} alt="Belarusian" />
        </div>
        <div className={style.name}>Беларускi</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="en">
        <div className={style.img}>
          <img src={GB} alt="English" />
        </div>
        <div className={style.name}>English</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="fr">
        <div className={style.img}>
          <img src={FR} alt="France" />
        </div>
        <div className={style.name}>Français</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="de">
        <div className={style.img}>
          <img src={DE} alt="Germany" />
        </div>
        <div className={style.name}>Deutsch</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="it">
        <div className={style.img}>
          <img src={IT} alt="Italy" />
        </div>
        <div className={style.name}>Italiano</div>
      </div>
    
      <div className={style.LanguageItem} translate="no" data-lang="ja">
        <div className={style.img}>
          <img src={JA} alt="Japanese" />
        </div>
        <div className={style.name}>日本語</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="pl">
        <div className={style.img}>
          <img src={PL} alt="Polish" />
        </div>
        <div className={style.name}>Polski</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="ru">
        <div className={style.img}>
          <img src={RU} translate="no" alt="Russian" />
        </div>
        <div className={style.name}>Русский</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="tr">
        <div className={style.img}>
          <img src={TR} alt="Turkish" />
        </div>
        <div className={style.name}>Türkçe</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="uk">
        <div className={style.img}>
          <img src={UA} alt="Ukrainian" />
        </div>
        <div className={style.name}>Українська</div>
      </div>
    </div>
  );
};

export default LanguageList;
