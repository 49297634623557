import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

const loading = keyframes`
  0% {
    background-color: #eee; 
  }
  100% {
    background-color: #ddd; 
  }
`;

export const ButtonsFilter = styled.div`
  display:flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
    & .swiper-wrapper{
        height: 38px;
    }
  & .swiper-slide{
    width: max-content !important;
  }
`;
export const SkeletonWrapper = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    overflow: hidden;
`;
export const SkeletonElement = styled.div`
    position: relative;
    height: 35px;
    width: 200px;
    background: ${baseTheme.colors.standard};
    border-radius: 10px;
    animation: ${loading} 1s infinite alternate; 
    & .button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0;
        animation: none;
        background-color: #e0e0e0;
    }

`;
