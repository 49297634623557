import React, { useCallback, useState } from "react";
import { useLocation, useNavigate  } from 'react-router-dom';
import * as styled from "./OperationDetails.style";
import { baseTheme } from "src/Style/baseTheme";
import { UrlMain, UrlHistory } from "../../../Static/Url";
import { Back } from "src/Components/Back";
import { ReactComponent as Close } from "../../../Svg/close.svg";
import { ReactComponent as Time } from "../../../Svg/time.svg";
import { ReactComponent as Accept } from "../../../Svg/accept.svg";
import { ReactComponent as Dollar } from "../../../Svg/dollar-sign.svg";
import { ReactComponent as Repeat } from "../../../Svg/repeat.svg";
import { ReactComponent as Replenishment } from "../../../Svg/replenishment.svg";
import { ReactComponent as Transfer } from "../../../Svg/transfer.svg";
import { ReactComponent as Mbrilogout } from "../../../Svg/mbrilogout.svg";
import axios from "axios";
import { MainButton } from "src/Components/MainButton";
import { Manual } from "src/Components/Manual";
import { ReceiptScreen } from "src/Components/ReceiptScreen";


export const OperationDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [apiData, setApiData] = useState(location.state.OperationDetails);
    const [imageSrc, setImageSrc] = useState(null)
    const [showImage, setShowImage] = useState(false);
    const [expect, setExpect] = useState(false);
    
    console.log(apiData);
    
    const authToken = localStorage.getItem('authToken');
    const orderId = localStorage.getItem('orderId');
    const orderType = localStorage.getItem('orderType');

    console.log(apiData?.extra?.text);
    console.log(apiData.record_type);
    
    const getIcon = (item) => {
        if (item?.record_type === "inout" && item?.debit === false) return <styled.ItemSvg color={`${baseTheme.colors.cardColor}`}><Mbrilogout /></styled.ItemSvg>;
        if (item?.record_type === "inout") return <styled.ItemSvg  color='#EECC55'><Dollar /></styled.ItemSvg>;
        if (item?.record_type === "exchange") return <styled.ItemSvg color='#55ACEE'><Repeat /></styled.ItemSvg>;
        if (item?.record_type === "rent") return <styled.ItemSvg color='#FF8A71'><Replenishment /></styled.ItemSvg>;
        if (item?.record_type === "transfer") return <styled.ItemSvg color='#965E91'><Transfer /></styled.ItemSvg>;
        return <styled.ItemSvg color='#55ACEE'><Repeat /></styled.ItemSvg>;
    };

    const addSpaces = (num) => {
        if (!num) return num;
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
      };
      
    const fetchDataHistory = useCallback(async () => {
        
        try {
        const response = await axios.get(`${UrlHistory}${"?limit=1"}&${`order_id=${apiData.order_id}`}`,   { 
            headers: {
                'Authorization': `Bearer ${authToken}`
            }});

        setApiData(Object.values(response)[0].data[0]);
        navigate('/operation_details', { state: { OperationDetails } });

        } catch (error) {
        console.error("Ошибка при получении данных:", error);
        }
    }, [apiData.order_id, authToken]);

    const fetchDataNext = useCallback(async () => {
            try {
                const response = await axios.get(`${UrlMain}get_ready?${`order_type=${apiData.record_type}`}&${`order_id=${apiData.order_id}`}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                      }
                  });
                fetchDataHistory()
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }
    }, [authToken, orderId, orderType]);

    const fetchDataImg = useCallback(async () => {
        if (apiData?.extra?.path) {
            try {
                const response = await axios.get(`${UrlMain}img/${apiData?.extra?.path}`, {
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                      }
                  });
                  fetchDataHistory()
                const imageUrl = URL.createObjectURL(response.data);
                setImageSrc(imageUrl);
                setShowImage(true);
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }
        } else {
        }
    }, [apiData?.extra?.path]);

      const handleClose = useCallback(() => {
        setShowImage(false); 
        setTimeout(() => setImageSrc(null), 500); 
      }, []);
    return(
    <>
        <styled.Container>
            <styled.BackWrapper>
                <Back continuation={false} Title={undefined}/>
            </styled.BackWrapper>
            <styled.HeaderWrapper>
            <styled.IconWrapper>
                {getIcon('exchange')}
                    <styled.StatusIcon status={apiData?.status}>
                       {apiData?.status === true ? <Accept /> : <Time />}
                    </styled.StatusIcon>
            </styled.IconWrapper>
                <styled.Date>
                {apiData?.date} в {apiData?.time}
                </styled.Date>
                <styled.Amount>
                    {addSpaces(Number(apiData?.amount) + Number(apiData?.from_balance))} {apiData?.symbol}
                </styled.Amount>
                <styled.Status>
                    <styled.StatusText status={apiData?.status}>
                        {(apiData?.status === null) && "Ожидает оплаты"}
                        {(apiData?.status === false) && (!!!apiData?.extra?.path) && "Ожидает подтверждения"}
                        {(apiData?.status === false) && (!!apiData?.extra?.path) && "Ожидает получения"}
                        {(apiData?.status === true) && "Выполнен"}
                    </styled.StatusText>
                </styled.Status>
            </styled.HeaderWrapper>
            <styled.MainWrapper>
                <styled.MainItem>
                    <styled.ItemTitle>
                        Тип  платежа
                    </styled.ItemTitle>
                    <styled.ItemData>
                    {apiData?.label}
                    </styled.ItemData>
                </styled.MainItem>
                <styled.MainItem>
                    <styled.ItemTitle>
                        Сумма оплаты
                    </styled.ItemTitle>
                    <styled.ItemData>
                        {addSpaces(apiData?.amount)}&nbsp;<styled.ItemDataSymbol>{apiData?.symbol}</styled.ItemDataSymbol>
                    </styled.ItemData>
                </styled.MainItem>
                <styled.MainItem>
                    <styled.ItemTitle>
                        Сумма к получению
                    </styled.ItemTitle>
                    <styled.ItemData>
                        {addSpaces(apiData?.output_amount)} {apiData?.output_symbol} 
                    </styled.ItemData>
                </styled.MainItem>
                {apiData?.from_balance !== "0" && 
                <styled.MainItem>
                    <styled.ItemTitle>
                        Списанно с баланса
                    </styled.ItemTitle>
                    <styled.ItemData>
                        {addSpaces(apiData?.from_balance)}
                    </styled.ItemData>
                </styled.MainItem>}
                <styled.MainItem>
                    <styled.ItemTitle>
                        Тип выдачи
                    </styled.ItemTitle>
                    <styled.ItemData>
                    {apiData?.type}
                    </styled.ItemData>
                </styled.MainItem>
                {apiData?.extra?.text && (
                    <styled.ManualWrapper>
                        <Manual Manual={apiData?.extra?.text} />
                    </styled.ManualWrapper>
                )}
            </styled.MainWrapper>
            {(apiData?.status === false) && (!!!apiData?.extra?.path) ?  
                ((!!!apiData?.extra?.get_ready) ?
                <styled.MainButtonWrapper onClick={fetchDataNext}>
                    <MainButton title={"Готов получить"} />
                </styled.MainButtonWrapper> :
                <styled.MainButtonWrapper onClick={fetchDataHistory}>
                    <MainButton title={"Обновить"} />
                </styled.MainButtonWrapper>
                ) :((apiData?.status === false || apiData?.status === true) && (!!apiData?.extra?.path) && !imageSrc ? 
                <styled.MainButtonWrapper onClick={fetchDataImg}>
                    <MainButton title={"Данные для получения"} />
                </styled.MainButtonWrapper>
                : null
                )}
            {imageSrc && <ReceiptScreen img={imageSrc} show={showImage} onClose={handleClose} />}
        </styled.Container>
    </>
)}

