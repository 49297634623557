import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const ReceiptScreen = styled.div`
`;
export const Container = styled.div`
  position: relative;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    position: absolute;
    left: 0px;
    top: 16px;
  }
`;
export const ImgWrapper = styled.div<{show: boolean}>`
    position:  fixed;
    background-color: ${baseTheme.colors.standard};
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    transition: transform 0.5s ease-in-out;
    transform: ${({ show }) => show ? 'translateX(0)' : 'translateX(-100%)'};
    & img{
      height: auto;
    }
`;
export const Close = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
`;