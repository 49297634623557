import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 65px 20px;
  position: relative;
`;

export const BackWrapper = styled.div`
`;
export const SearchWrapper = styled.div<{searchScroll: boolean}>`
  ${(props) =>
    props.searchScroll && css`
    margin-bottom: 80px;
  `}
`;

export const Accordions = styled.div`
  
`;

export const LoadingMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  color: ${baseTheme.colors.bodyText};
  opacity: 0.8;
`;

export const LoadMoreMessage = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #ccc;
  text-align: center;
  color: ${baseTheme.colors.bodyText};
  opacity: 0.8;
`;

export const NoMoreQuestionsMessage = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #ccc;
  text-align: center;
  color: ${baseTheme.colors.bodyText};
  opacity: 0.8;
`;

