import React from "react";
import { SearchInputContainer, SearchInput, ClearButton } from "./Search.style";
import closeIcon from "../../Svg/close.svg";

interface SearchProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  placeholder?: string;
  textColor?: string;
  inputHeight?: number;
  searchScroll?: boolean;
  showClearButton?: boolean;
}

export const Search: React.FC<SearchProps> = ({
  searchTerm,
  setSearchTerm,
  placeholder = "Поиск",
  textColor,
  inputHeight,
  searchScroll,
  showClearButton = false,
}) => {
  const handleClearInput = () => {
    setSearchTerm("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <SearchInputContainer searchScroll={searchScroll}>
      <SearchInput
        type="text"
        searchScroll={searchScroll}
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        textColor={textColor}
        style={{ height: inputHeight }}
      />
      {searchTerm && showClearButton && (
        <ClearButton onClick={handleClearInput}>
          <img src={closeIcon} alt="Очистить" />
        </ClearButton>
      )}
    </SearchInputContainer>
  );
};
 