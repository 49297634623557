import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const query = new QueryClient()

const domNode = document.getElementById("root");
if (domNode) {
  const root = createRoot(domNode);
  root.render(
    <QueryClientProvider client={query} >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  );
}