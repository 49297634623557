import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as styled from "./TelegramModal.style";
import TelegramLoginButton from '../Pages/TelegramAuth/TelegramButton'
type tgWebAuth = {
    auth_date:number,
    first_name:string,
    hash:string,
    id:number,
    photo_url:string,
    username:string
}

const TelegramModal = () => {
    let name = "approcket_bot"
    const navigate = useNavigate()
    const handleBot =(user:tgWebAuth) =>{
        localStorage.setItem('tgUser', JSON.stringify(user))
        console.log("Авторизация телеграмм сработала. User = ", user)
        console.log("handleBot localStorage tgUsernameWeb", localStorage.getItem('tgUser'))
        navigate('/')
    }
  return (
    <div>
        <styled.Container >
            <styled.Inner >
                <h1>
                    Добро пожаловать
                </h1>
                <p>
                    Для продолжения вам необходимо авторизоваться через телеграмм
                </p>
                <TelegramLoginButton 
                botName={name}
                buttonSize="large"
                cornerRadius={20}
                usePic={false}
                className=""
                dataOnauth={handleBot}/>
            </styled.Inner>
        </styled.Container>
    </div>
  )
}

export default TelegramModal