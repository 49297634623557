import { baseTheme } from "src/Style/baseTheme";
import styled, { css, keyframes } from "styled-components";

export const AmountDisplay = styled.div``;

export const AmountWrap = styled.div`
  position: relative;
  min-height: 59px;
  display : flex;
  width: 100%;
  border-radius: 10px;
  background: ${baseTheme.colors.standard};
  box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
  padding-left: 10px;
  padding-top: 5px;
  font-size: 40px;
  margin-bottom: 30px;
  & svg{
    border: 1px solid ${baseTheme.colors.borderFlag};
    border-radius: 50%;
  }
`;

export const BalanceBorder = styled.div`
  border-right: 1px solid ${baseTheme.colors.borderCurrency};
  min-height: 45px;
  margin: 5px 7px 5px;
  opacity: 0.5;
`;
export const Currency = styled.div`
  font-size: 40px;
  align-items : center;
  svg {
    width: 40px;
    height: 40px;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  gap: 10px;
  width : 100px;
  position: relative;
  padding-right: 6px;
  


`;

export const Flag = styled.div`
  width: 40px;
  height: 40px;
  transform: translateY(6px);
  & svg {
    width: 40px;
    height: 40px;
  }
`;

export const OriginWrapp = styled.div``;
export const Title = styled.div`
  color: ${baseTheme.colors.text};
  line-height: 160%;
  font-size: 21px;
  
`;
export const Origin = styled.div`
  margin-top: -5px;
  line-height: 160%;
  font-size: 14px;
  color: ${baseTheme.colors.bodyText};
`;
