import styled, { css } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const MainMenu = styled.div`
    position: relative;
    margin-bottom: 107px;

`;
export const MenuWrapper = styled.div`
    position: absolute;
    top: -30px;
    width: calc(100% - 40px) ;
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    padding: 0 13px 14px;
    border-bottom: 1px solid ${baseTheme.colors.border};
`;
export const MenuItem = styled.div<{disable: boolean}>`
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    ${(props) => props.disable && css`
        opacity: 0.6;
    `}
`;
export const ItemSvg = styled.div<{color:string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: ${props => props.color};
    margin-bottom: 8px;
`;
export const svgStyle = styled.div<{color:string}>`
    width: 24px;
    height: 10px;
    color: ${props => props.color};
`;
export const ItemTitle = styled.div`
    text-align: center;
    font-size: 15px;
`;
