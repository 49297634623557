import React, { useCallback, useState } from "react";
import * as styled from "./MainButton.style";
import { ReactComponent as Maestro } from "../../Svg/CardFirm/maestro.svg";
import { ReactComponent as Visa } from "../../Svg/CardFirm/visa.svg";
import { ReactComponent as MasterCard } from "../../Svg/CardFirm/master_card.svg";
import { ReactComponent as Copy } from "../../Svg/copy.svg";
import { ReactComponent as Sim } from "../../Svg/sim.svg";

  
  export const MainButton = ({ title }) => {


    return ( 
        <styled.MainButton>
            <styled.MainButtonTitle>{title}</styled.MainButtonTitle>
        </styled.MainButton>
    );
};