import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFetchData = (initialUrl = []) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);


  const fetchData = async (url) => {
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    } finally {
    }
  } 
  useEffect(() => {
    fetchData(initialUrl)
  }, [initialUrl])
  return { data,fetchData, isLoading,  };
  
};
