import { toast } from 'react-toastify';

const useToastMessage = () => {
  const showToastMessage = (message: string) => {
    toast(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  };

  return showToastMessage;
};

export default useToastMessage;
