import styled, {  css } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";
import { InputHTMLAttributes } from "react";

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  textColor?: string; 
  searchScroll: boolean
}

export const SearchInputContainer = styled.div<{searchScroll: boolean}>`
  position: relative;
  margin-bottom: 30px;
  ${(props) =>
    props.searchScroll && css`
    position: fixed;
    z-index: 1;
    left: 20px;
    right: 20px;
    top: 0;
  `}
`;

export const SearchInput = styled.input<SearchInputProps>`
  padding: 20px 40px 20px 20px;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 63px;
  box-shadow: 0px 0px 5px 5px ${baseTheme.colors.boxShadow};
  outline: none;
  color: ${props => props.textColor || baseTheme.colors.bodyText}; 
  font-size: 18px;
  &::placeholder {
    color: ${baseTheme.colors.bodyText}; 
  }
  ${(props) =>
    props.searchScroll && css`
    border-radius: 0 0 10px 10px;
  `}
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  right: 14px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
`;
