import styled, { css } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const History = styled.div`
`;
export const HistoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
export const HistoryItem = styled.div`
    background: ${baseTheme.colors.standard};
    border-radius: 10px;
    gap: 14px;
    padding: 15px 10px;
    box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
`;
export const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 0px;
    width: 100%;
`;
export const ItemSvg = styled.div <{tresh?:boolean, position?:boolean,color?: string}>`
    position: relative; 
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${baseTheme.colors.main};
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: 1 / 1 / 3 / 2;
    & svg {
        fill: ${({ tresh }) => tresh && "#fff"};
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%,50%);
    }
    & path{
        stroke: ${baseTheme.colors.standard};
    }
    ${(props) => props.color && css`
        
        background: ${props.color};
    `}
    ${(props) => props.tresh && css`
        & svg{
            stroke: ${baseTheme.colors.standard} ;
        }
    `}
`;

export const ItemSvgPosition = styled.div <{status: boolean}>` 
    background-color:  ${({ status }) => 
        status === null ? `${baseTheme.colors.link}` : 
        status === false ? `${baseTheme.colors.rent}` : 
        `${baseTheme.colors.operation}` };
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%,-25%);
    & svg{
        fill: ${baseTheme.colors.standard};
        width: 15px;
        height: 15px;
        top: 0;
        right: 0;
        transform: translate(-15%,16%);
    }
`;
export const ItemTitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: 1 / 2 / 2 / 4;
`;
export const ItemTitle = styled.h6`
    font-size: 18px;
`;
export const ItemData = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
`;
export const ItemSubetitleWrap = styled.div`
    display: flex;
    gap: 8px;
    grid-area: 2 / 2 / 3 / 3;
`;
export const ItemSubtitle = styled.div`
    font-size: 14px;
    color: ${baseTheme.colors.bodyText};
`;
export const ItemWrap = styled.div`
`;
export const Block = styled.div`
`;
export const ItemMoney = styled.div <{color: string}>`
    font-size: 18px;
    grid-area: 2 / 3 / 3 / 4; 
    display: flex;
    justify-content: flex-end;

    color: ${({ color }) => color === null || color === undefined
        ? 'inherit'
        : color ? `${baseTheme.colors.introduction}`
        :  `${baseTheme.colors.link}`}; 
`;
