import styled from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

export const WarningWrapper = styled.div`
  position: fixed;
  z-index: 9000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${baseTheme.colors.borderblure};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Warning = styled.div`
  background: white;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
`;

export const Text = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.4;
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:first-of-type {
    background: ${baseTheme.colors.link};
    color: ${baseTheme.colors.standard};
  }

  &:last-of-type {
    background: ${baseTheme.colors.operation};
    color: ${baseTheme.colors.standard};
  }
`;