import React from "react";
import {useToast} from "../useToast/ToastContext";
import * as styled from "./GeneralMenu.style";
import { ReactComponent as Privacy } from "../../Svg/GeneralMenu/shield.svg";
import { ReactComponent as Report } from "../../Svg/GeneralMenu/report 1.svg";
import { ReactComponent as Wallet } from "../../Svg/GeneralMenu/wallet 1.svg";
import { ReactComponent as Order } from "../../Svg/GeneralMenu/order.svg";
import { Link, useLocation } from "react-router-dom"; 

export const GeneralMenu = () => {
    const showToast = useToast(); 
    const location = useLocation(); 

    return (
        <styled.GeneralMenu>
            <styled.GeneralMenuWrapper>
                <Link to="/">
                    <styled.Item active={location.pathname === "/"}>
                        <styled.ItemSvg>
                            <Report />
                        </styled.ItemSvg>
                    </styled.Item>
                </Link>
                <Link to="/order_product">
                    <styled.Item active={location.pathname === "/order_product"}>
                        <styled.ItemSvg>
                            <Order />
                        </styled.ItemSvg>
                    </styled.Item>
                </Link>
                
                <Link to="/faq_page">
                    <styled.Item active={location.pathname === "/faq_page"}>
                        <styled.ItemSvg>
                            <Wallet />
                        </styled.ItemSvg>
                    </styled.Item>
                </Link>
                <Link to="/privacyPolicy">
                    <styled.Item active={location.pathname === "/privacyPolicy"}>
                        <styled.ItemSvg>
                            <Privacy />
                        </styled.ItemSvg>
                    </styled.Item>
                </Link>
            </styled.GeneralMenuWrapper>
        </styled.GeneralMenu>
    );
};