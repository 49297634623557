import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import * as styled from "./NewCard.style";
import { UrlNotifications, UrlNotifRead, UrlMain } from "../../../Static/Url";
import { Back } from "src/Components/Back";
import { Button } from "src/Components/Button";
import { Title } from "src/Components/Title";
import { Subtitle } from "src/Components/Subtitle";
import { currencyData } from "src/Static/SelectedCurrencyData";
import { PeriodData } from "src/Static/PeriodData";
import { filmCards } from "src/Static/FirmCard";
import { MainButton } from "src/Components/MainButton";
import { AmountDisplay } from "src/Components/AmountDisplay";
import { ReactComponent as Question } from "src/Svg/Flag/undefindedFlag.svg";

export const NewCard = () => {
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("VISA");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [amount, setAmount] = useState(0);
  const [title, setTitle] = useState("none");
  const [subTitle, setSubTitle] = useState("none");

  const [symbol, setSymbol] = useState(Question);

  const handleCurrencyClick = (currency) => {
      setSelectedCurrency(currency.code);
      setSymbol(currency.symbol);
      setSubTitle(currency.subtitle)
      setTitle(currency.title)
  };
  function handlePayment(payment) {
    setSelectedPayment(payment);
  }
  function handlePeriod(period) {
    setAmount(100 * period.count);
    setSelectedPeriod(period.day);
  }
  const addSpaces = (num) => {
    if (!num) return num;
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
  };
  return (
    <>
      <styled.Container>
        <styled.BackWrapper>
          <Back continuation={true} Title={undefined} />
        </styled.BackWrapper>
        <Title Title={"Аренда карты"} />
        {false ? (
          <>
            <styled.Wrapper>
              <styled.SkeletonWrapper>
                <styled.SkeletonElement className="title" />
                <styled.SkeletonSwiperWrapper>
                  {Array.from({ length: 4 }, (_, index) => (
                    <React.Fragment key={index}>
                      <styled.SkeletonElement className="swiperItem" />
                    </React.Fragment>
                  ))}
                </styled.SkeletonSwiperWrapper>
              </styled.SkeletonWrapper>
            </styled.Wrapper>
            <styled.Wrapper>
              <styled.SkeletonWrapper>
                <styled.SkeletonElement className="title" />
                <styled.SkeletonSwiperWrapper>
                  {Array.from({ length: 4 }, (_, index) => (
                    <React.Fragment key={index}>
                      <styled.SkeletonElement className="swiperItem" />
                    </React.Fragment>
                  ))}
                </styled.SkeletonSwiperWrapper>
              </styled.SkeletonWrapper>
            </styled.Wrapper>
            <styled.Wrapper>
              <styled.SkeletonWrapper>
                <styled.SkeletonElement className="Text" />
              </styled.SkeletonWrapper>
            </styled.Wrapper>
            <styled.Wrapper>
              <styled.SkeletonWrapper>
                <styled.SkeletonElement className="title" />
                <styled.SkeletonSwiperWrapper>
                  {Array.from({ length: 4 }, (_, index) => (
                    <React.Fragment key={index}>
                      <styled.SkeletonElement className="swiperItem" />
                    </React.Fragment>
                  ))}
                </styled.SkeletonSwiperWrapper>
              </styled.SkeletonWrapper>
            </styled.Wrapper>
            <styled.Wrapper>
              <styled.SkeletonWrapper>
                <styled.SkeletonElement className="title" />
                <styled.SkeletonSwiperWrapper>
                  <styled.SkeletonElement className="swiperItem" />
                </styled.SkeletonSwiperWrapper>
              </styled.SkeletonWrapper>
            </styled.Wrapper>
          </>
        ) : (
          <>
            <styled.Wrapper>
              <Subtitle Title={"Срок аренды:"} />
              <styled.NewCardButtonWrapper>
                <Swiper spaceBetween={10} slidesPerView={"auto"}>
                  {PeriodData.map((period) => (
                    <SwiperSlide>
                      <Button
                        onClick={() => handlePeriod(period)}
                        Title={period.day}
                        active={selectedPeriod === period.day}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </styled.NewCardButtonWrapper>
            </styled.Wrapper>
            <styled.Wrapper>
              <Subtitle Title={"Платежная система:"} />
              <styled.NewCardPaymentSystemButton>
                <Swiper spaceBetween={10} slidesPerView={"auto"}>
                  {filmCards.map((card) => (
                    <SwiperSlide key={card.code}>
                      <Button
                        Svg={card.image}
                        active={selectedPayment === card.code}
                        onClick={() => handlePayment(card.code)}
                        disable={card.disabled}
                        Title={""}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </styled.NewCardPaymentSystemButton>
            </styled.Wrapper>
            <styled.Wrapper>
              <styled.NewCardText>
              Арендованная карта полностью совместима с Apple Pay и Google Pay, Go Pay и 
              другими платежными системами что позволяет вам совершать оплаты в любых терминалах поддерживающих 
              эти сервисы.
              </styled.NewCardText>
            </styled.Wrapper>
            <styled.Wrapper>
              <Subtitle Title={"Выберите валюту оплаты:"} />
              <styled.NewCardSwiper>
                <Swiper spaceBetween={10} slidesPerView={"auto"}>
                  {currencyData
                    .filter((currency) => currency.code === "RUB" || currency.code === "USDT" )
                    .map((currency) => {
                    return (
                      <SwiperSlide key={currency.code}>
                        <Button
                          Title={currency.title}
                          active={selectedCurrency === currency.code}
                          onClick={() => handleCurrencyClick(currency)}
                          disable={currency.disabled}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </styled.NewCardSwiper>
            </styled.Wrapper>
            <styled.Wrapper>
              <Subtitle Title={"Сумма:"} />
              <AmountDisplay
                title={title}
                label={subTitle}
                Symbol={symbol ? symbol : "?"}
                Number={addSpaces(amount)}
              />
            </styled.Wrapper>
          </>
        )}
        <MainButton title={"Арендовать"} />
      </styled.Container>
    </>
  );
};
