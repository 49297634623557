import { createGlobalStyle } from "styled-components";
import { baseTheme } from "./baseTheme";
import Regular from "../Fonts/Roboto-Regular.ttf";
import Medium from "../Fonts/Roboto-Medium.ttf";
import Productsans from "../Fonts/productsans.ttf";

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Roboto';
  src: url(${Medium}) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(${Regular}) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'productsans';
  src: url(${Productsans}) format('truetype');
  font-weight: 400;
}
#google_translate_element{width:300px;float:right;text-align:right;display:block}
.goog-te-banner-frame.skiptranslate { display: none !important;} 
body { top: 0px !important; }
#goog-gt-tt{display: none !important; top: 0px !important; } 
.goog-tooltip skiptranslate{display: none !important; top: 0px !important; } 
.activity-root { display: hide !important;} 
.status-message { display: hide !important;}
.started-activity-container { display: hide !important;}
.skiptranslate {display:none}
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'productsans';
    font-weight: 400;
    color: ${baseTheme.colors.text};
  }
  body {
    height: 100%
  }
  .App{
    background-color: ${baseTheme.colors.main};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    & .Toastify__toast-theme--light{
      min-height: 77px;
      border-radius: 20px 20px 0 0;
      & .Toastify__close-button{
        display: none
      }
      & svg{
        fill: ${baseTheme.colors.link};
      }
    }
    & .swiper-slide {
    max-width: max-content;
}
  }
  div {
    padding: 0;
    margin: 0;
  }
  li {
    list-style-type: none;
  }
  h1, h2, h3, h4, h5 {
    margin: 0;
    font-weight: 100;
  }
  img{
    width: 100%;
    height: 100%;
  }
  a{
    text-decoration: none;
    color: inherit;
  }

  .notification-wrapper {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 300px;
}

.notification-item {
    box-shadow: 0 0 10px rgba(0,0,0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
    width: 300px;
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}
.Toastify__close-button{
  display: none;
}
.notification-item.exit {
    animation: SlideRight 0.4s;
    animation-fill-mode: forwards;
}

.notification-item p {
    margin: 0;
    padding: 10px;
}

.notification-item .bar {
    height: 10px;
}

.notification-item.success .bar {
    background-color: #65d266;
}

.notification-item.error .bar {
    background-color: red;
}
`;

