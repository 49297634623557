import styled, {  css } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

export const ReviewContainer = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  background-color: ${baseTheme.colors.standard};
  border-radius: 6px;
  box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
`;

export const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ReviewerName = styled.div`
  font-weight: bold;
  margin-right: 8px;
`;

export const ReviewDate = styled.div`
  color: ${baseTheme.colors.bodyText};
`;

export const ReviewTitle = styled.h3`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
`;

export const ReviewText = styled.p`
  font-size: 16px;
  color: ${baseTheme.colors.bodyText};
`;

export const StarRating = styled.div`
  display: flex;
  margin-right: 8px;
  gap: 2px;
  & path{
    transform: translate(15px,15px)
  }
`;