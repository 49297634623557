import styled from "styled-components";
import spacecadet from '../../../Images/spacecadet-Photoroom.png'
import moonshard from '../../../Images/moonshard.png'

export const Container = styled.div`
position: relative;
overflow: hidden;
z-index: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
padding:150px 10px 0px 10px;
align-items: center;
text-align: center;
height: 100vh;
gap:200px;
 h1{
    font-weight: 400;
 span{
    font-weight: 700;
 }
 }
 @media (min-width:490px){
    z-index: 10;
    h1{
      margin-bottom: 170px;
    }
 };
 @media (min-width: 760px) {
    gap:100px;
    padding-top: 110px;
    h1{
        font-size: 64px;
    }
 }
`
export const ImageMoonshard = styled.div`
   position:absolute;
   top: -40px;
   right: -80px;
   width:200px;
   height:200px;
   background-image: url(${moonshard});
   background-size:cover;
`
export const ImageSpaceCadet = styled.div`
    position:absolute;
    top:45%;
    left:50%;
    bottom: 0;
    right:0;
    transform: translate(-50%, -50%);
    z-index: -10;
    width:200px;
    height:200px;
    background-image:url(${spacecadet});
    background-size:cover;
`
export const Cicrle = styled.div`
    position:absolute;
    top:-30px;
    right:-100px;
    border-radius: 50%;
    z-index: -10;
    width:200px;
    height:200px;
    background-color:#68c3c6;
    background-size:cover;
`
export const Cicrle2 = styled.div`
    position:absolute;
    top:20%;
    left:-150px;
    border-radius: 50%;
    z-index: -10;
    width:200px;
    height:200px;
    background-color:#72b6b9;
    background-size:cover;
`
export const Button = styled.button`
padding: 10px;
width: 150px;
font-size: 20px;
border-radius: 20px;
background-color: skyblue;
:active{
   background-color: gray;
}
`