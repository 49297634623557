import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const Back = styled.div`
`;
export const Icon = styled.div`

`;
export const Container = styled.div`
  position: relative;
  height: 47px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: start;
  
  & svg {
    height: 20px;
    width:20px;
    left: 0px;
    top: 16px;
  }
`;
export const Title = styled.h1`
  font-size: 25px;
`;