import React, { useState, useEffect, useCallback } from "react";
import * as styled from "./ExtraPage.style";
import { Title } from "../../Title";
import { MoreCard } from "../../MoreCard";
import { Back } from "src/Components/Back";
import jsonData from "../../../Static/ExtraProd";
import { useNavigate } from "react-router-dom";

export const ExtraPage = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCards(jsonData);
  }, []);

  const handleCardClick = (cardTitle) => {
    navigate("/extra_details", { state: { cardTitle } });
  };

 
  return (
    <>
      <styled.Container>
        <styled.BackWrapper>
          <Back continuation={true} Title={""} />
        </styled.BackWrapper>
        <Title Title={"Дополнительно"} />
        <styled.CardsContainer>
          {cards.map((card, index) => (
            <MoreCard
              placeTake={"4"}
              key={index}
              index={index}
              image={card.image}
              title={card.title}
              handleCardClick={handleCardClick}
            />
          ))}
        </styled.CardsContainer>
      </styled.Container>
    </>
  );
};
