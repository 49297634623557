import styled from "styled-components";
import { baseTheme } from "../../Style/baseTheme";



export const TitleInfo = styled.div`
    
    margin-bottom: 10px;
`;
export const TexteInfo = styled.div`
    padding-bottom: 30px;
`;