import React from "react";
import { useNavigate } from 'react-router-dom';
import * as styled from "./ExchangeCurrency.style";
import { Currency } from "../../../Currency";
import { flagData } from "../../../../Static/SelectedCurrencyData";




export const ExchangeCurrency = ({ data }) => {
  
  const navigate = useNavigate(); 
  const { name, label, rate } = data;
  const FlagIcon = flagData.find(item => item.code === data.name);

  const handleNavigate = () => {
      navigate('/receiving', { state: { activeCurrency: name } });
  };

  return (
    <>
      <styled.ExchangeCurrencyWrapper onClick={handleNavigate}>
        <styled.ExchangeCurrency>
          <Currency FlagIcon={FlagIcon.symbol} title= {name} label={label} />
          <styled.TitleDollars>
          </styled.TitleDollars>
          <styled.TitleInDollars>
            {rate}
          </styled.TitleInDollars>
        </styled.ExchangeCurrency>
      </styled.ExchangeCurrencyWrapper>
    </>
  );
};