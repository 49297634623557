import React, { createContext, useContext, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [isToastActive, setIsToastActive] = useState(false);

  const showToastMessage = (message) => {
    if (isToastActive) {
      return;
    }
    setIsToastActive(true);
    toast(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
    setTimeout(() => {
      setIsToastActive(false);
    }, 5000);
  };

  return (
    <ToastContext.Provider value={showToastMessage}>
      {children}
      <ToastContainer
        position="bottom-right"
        limit={1}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </ToastContext.Provider>
  );
};