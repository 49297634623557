import React, { useCallback, useEffect, useMemo, useState } from "react";
import {useToast} from "../../useToast/ToastContext";
import {ReactComponent as Globe} from "../../../Svg/globe.svg"
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";
import * as styled from "./MainPage.style";
import { Link } from "react-router-dom";
import { ReactComponent as Profile } from "../../../Svg/Profile icon.svg";
import { ReactComponent as Notification } from "../../../Svg/GeneralMenu/notification-bell 1.svg";
import { ReactComponent as Reviews  } from "../../../Svg/messag.svg";
import {
  UrlCards,
  UrlHistory,
  UrlNotifications,
  UrlMain,
} from "../../../Static/Url";
import { MainMenu } from "../../MainMenu";
import { HistoryList } from "../../HistoryList";
import { useNavigate } from "react-router-dom";

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        ready: () => void;
        initData?: any;
        initDataUnsafe?: {
          user: {
            id: number;
            username: string;
          };
        };
      };
    };
  }
}

export const MainPage = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isRead, setIsRead] = useState(false);
  const [isLoadHistory, setIsLoadHistory] = useState(false);
  const [isLoadCard, setIsLoadCard] = useState(false);
  const [historyData, setHistoryData] = useState<any>();
  const [cardData, setCardData] = useState<any>();
  const [isToastActive, setIsToastActive] = useState(false);
  const showToast = useToast();
  
  const authToken = localStorage.getItem("authToken");
  
  
  const navigate = useNavigate();

  const handleNavigateToMoneyBox = () => {
    if (cardData?.data?.balance !== "0") {
      navigate("/money_box");
    }
  };

  const userPhotoData = useCallback(async () => {
    try {
      const response = await axios.get(`${UrlMain}user_photo`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const imageUrl = URL.createObjectURL(response.data);
      setImageSrc(imageUrl);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, [authToken]);


  const fetchCardData = useCallback(async () => {
    setIsLoadCard(false);
    try {
      const response = await axios.get(`${UrlCards}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setCardData(response.data);
      setIsLoadCard (true);
    } catch (error) {
      console.error("Ошибка при получении истории операций:", error);
    }
  }, [authToken]);
  
  const fetchHistoryData = useCallback(async () => {
    setIsLoadHistory(false);
    try {
      const response = await axios.get(`${UrlHistory}${"?limit=5"}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setHistoryData(Object.values(response.data));
      setIsLoadHistory(true);
    } catch (error) {
      console.error("Ошибка при получении истории операций:", error);
    }
  }, [authToken]);

  const fetchDataNotifications = useCallback(async () => {
    try {
      const response = await axios.get(
        `${UrlNotifications}${`?limit=6`}${`&page=0`}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const read = response?.data.data || [];
      const allRead = read?.every((notification) => notification.is_read);
      setIsRead(!allRead);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, [authToken]);

  useEffect(() => {
    fetchCardData();
  }, [fetchCardData]);
  useEffect(() => {
    userPhotoData();
  }, [userPhotoData]);
  useEffect(() => {
    fetchHistoryData()
  }, [fetchHistoryData]);
  useEffect(() => {
    fetchDataNotifications()
  }, [fetchDataNotifications]);
  console.log("Cookie mainpage = ", document.cookie)
  return (
    <styled.MainPage>
      <styled.Section>
        <styled.Container>
          <styled.HeaderWrapper>
            <styled.HeaderTopMenu>
              {imageSrc ? (
                <styled.Icon position>
                  <img src={imageSrc} alt="" />
                </styled.Icon>
              ) : (
                <styled.Icon position>
                  <Profile />
                </styled.Icon>
              )}
              {/* <styled.Balance onClick={handleNavigateToMoneyBox}>
                {isLoadCard ? (
                  cardData?.balance
                ) : (
                  <styled.SkeletonElement className="balanceAll" />
                ) }
              </styled.Balance> */}
              <styled.Links>

              <styled.StyledLink to="/reviews">
                <styled.Icon positionReviews={true}>
                  <Reviews />
                </styled.Icon>
              </styled.StyledLink>
              <styled.Icon positionRead={imageSrc}>
                <Link to="/notifications">
                  <Notification />
                  {isRead && <styled.IconNotif positionRead={imageSrc}/>}
                </Link>
              </styled.Icon>
              <styled.Icon>
                <Link to="/language">
                <styled.Icon>
                  <Globe/>
                </styled.Icon>
                </Link>
              </styled.Icon>
              </styled.Links>
            </styled.HeaderTopMenu>
          </styled.HeaderWrapper>
          <styled.Slider>
            <styled.MainMenuImg>
              
            </styled.MainMenuImg>
            {/* <Swiper
              spaceBetween={20}
              slidesPerView={"auto"}
              loop={true}
              pagination={true}
              modules={[Pagination]}
            >
              
              {/* {isLoadCard ? (
                cardData?.cards.map((card, index) => (
                  <SwiperSlide key={index}>
                    <Card card={card} />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide>
                  <styled.SkeletonWrapper>
                    <styled.SkeletonElement className="logo" />
                    <styled.SkeletonElement className="validity" />
                    <styled.SkeletonElement className="number" />
                    <styled.SkeletonElement className="balance" />
                    <styled.SkeletonElement className="balanceNum" />
                    <styled.SkeletonElement className="csv" />
                    <styled.SkeletonElement className="csvNum" />
                  </styled.SkeletonWrapper>
                </SwiperSlide>
              ) } 
              {/* <SwiperSlide>
                  <styled.CardWrapper onClick={() => showToast("Услуга в данный момент не доступна")}>
                    <styled.CardGradient></styled.CardGradient>
                    <styled.Card>
                      <Plus />
                    </styled.Card>
                  </styled.CardWrapper>
              </SwiperSlide> 
            </Swiper> */}
          </styled.Slider>
        </styled.Container>
      </styled.Section>
      <MainMenu />
      <styled.Container>
        <styled.HistoryWrapper>
          <styled.HistoryWrap>
            <styled.HistoryTitle>Последние операции</styled.HistoryTitle>
            <Link to="/history">
              <styled.HistoryButton>ВСЕ</styled.HistoryButton>
            </Link>
          </styled.HistoryWrap>
          {isLoadHistory ? (
            <HistoryList historyData={historyData ? historyData[0] : []} />
          ) : (
            Array.from({ length: 5 }, (_, index) => (
              <React.Fragment key={index}>
                <styled.SkeletonWrapperHistory>
                  <styled.SkeletonElement className="iconHistory" />
                  <styled.SkeletonElement className="titleHistory" />
                  <styled.SkeletonElement className="subtitleHistory" />
                  <styled.SkeletonElement className="amountHistory" />
                </styled.SkeletonWrapperHistory>
              </React.Fragment>
            ))
          )}
        </styled.HistoryWrapper>
      </styled.Container>
    </styled.MainPage>
  );
};
