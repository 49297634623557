import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";
import Bg from "../../../Images/BGAll.png";



export const Container = styled.div`
    padding-top: 5px ;
    padding-bottom: 130px;
    position: relative; 
`;
export const BackWrapper = styled.div`
    padding-left: 20px;
`;
export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
`;
export const IconWrapper = styled.div`
    position: relative;
`;
export const ItemSvg = styled.div <{tresh?:boolean, position?:boolean,color?: string}>`
    position: relative; 
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    & svg {
        fill: ${({ tresh }) => tresh && "#fff"};
        width: 24px;
        height: 24px;
    }
    & path{
        stroke: ${baseTheme.colors.standard};
    }
    ${(props) => props.color && css`
        background: ${props.color};
    `}
    ${(props) => props.tresh && css`
        & svg{
            stroke: ${baseTheme.colors.standard} ;
        }
    `}
`;
export const Date = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`;
export const Amount = styled.div`
    font-size: 30px;
    margin-bottom: 10px;
`;
export const Status = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 6px;
`;
export const StatusIcon = styled.div <{status: boolean | null}>`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color:  ${({ status }) => 
        status === null ? `${baseTheme.colors.link}` : 
        status === false ? `${baseTheme.colors.rent}` : 
        `${baseTheme.colors.operation}` };
    & svg{
        width: 14px;
        height: 14px;
    }
`;
export const StatusText = styled.h2<{status: boolean | null}>`
    font-size: 20px;
    color: ${({ status }) => 
        status === null ? `${baseTheme.colors.link}` : 
        status === false  ? `${baseTheme.colors.rent}` : 
        `${baseTheme.colors.operation}` };
`;
export const MainWrapper = styled.div`
    padding: 0 20px;
`;
export const MainItem = styled.div`
    padding: 17px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
`;
export const ItemTitle = styled.div`
    line-height: 160%;
    font-size: 14px;
`;
export const ItemData = styled.h3`
display: flex;
    font-size: 18px;
`;
export const ItemDataSymbol = styled.strong`
    font-size: 18px;
`;
export const ManualWrapper = styled.div`
    padding-top: 18px;
`;
export const MainButtonWrapper = styled.div`
    margin-left: 20px;
`;