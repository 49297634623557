import React, { useCallback, useState, useEffect } from "react";
import * as styled from "./Success.style";
import { ReactComponent as Icon } from "../../../Svg/Success/icon.svg";
import { MainButton } from "src/Components/MainButton";
import { UrlMain } from "../../../Static/Url";
import axios from "axios";
import { Link } from "react-router-dom";

export const Success = () => {
    const [link, setLink] = useState("");
    const [apiData, setApiData] = useState(null);
    const [savedOrderId, setSavedOrderId] = useState(null);
    const [savedOrderType, setSavedOrderType] = useState(null);

    const authToken = localStorage.getItem('authToken');

    useEffect(() => {
        const orderId = localStorage.getItem('orderId');
        const orderType = localStorage.getItem('orderType');
        console.log("Извлеченный orderId:", orderId);
        console.log("Извлеченный orderType:", orderType);
        setSavedOrderId(orderId);
        setSavedOrderType(orderType);
    }, []);

    const addSpaces = (num) => {
        if (!num) return num;
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    };

    const setIsLoadId = useCallback(async () => {
        try {
            const response = await axios.get(`${UrlMain}auth/${window.Telegram.WebApp?.initData}`);
            const authToken = response.headers["authorization"];
            localStorage.setItem('authToken', authToken);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    }, []);

    const fetchData = useCallback(async () => {
        if (!savedOrderId || !savedOrderType) return;

        try {
            const response = await axios.get(`${UrlMain}success_payment?order_id=${savedOrderId}&order_type=${savedOrderType}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            setApiData(response.data);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    }, [savedOrderId, savedOrderType, authToken]);

    useEffect(() => {
        setIsLoadId();
    }, [setIsLoadId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <styled.Success>
            <styled.Container>
                <Icon />
                <styled.Title>
                    Успех!
                </styled.Title>
                {apiData &&
                    <styled.SubTitle>
                        {addSpaces(apiData.amount)} {apiData.symbol}
                    </styled.SubTitle>
                }
                <styled.Text>
                    Ваша транзакция в процессе!
                </styled.Text>
            </styled.Container>
            <styled.MainButtonWrapper>
                <Link to="/">
                    <MainButton title={"Готово"} />
                </Link>
            </styled.MainButtonWrapper>
        </styled.Success>
    );
};