import React, { useCallback, useEffect, useState } from "react";
import * as styled from "./Notifications.style";
import { useLocation, useNavigate  } from 'react-router-dom';
import { UrlNotifications, UrlNotifRead,UrlMain, UrlHistory } from "../../../Static/Url";
import { Back } from "src/Components/Back";
import { ReactComponent as Close } from "../../../Svg/close.svg";
import { ReactComponent as Dollar } from "../../../Svg/dollar-sign.svg";
import { ReactComponent as Repeat } from "../../../Svg/repeat.svg";
import { ReactComponent as Replenishment } from "../../../Svg/replenishment.svg";
import { ReactComponent as Transfer } from "../../../Svg/transfer.svg";
import { ReactComponent as Сard } from "../../../Svg/card.svg";
import { ReactComponent as Smartphone } from "../../../Svg/smartphone.svg";
import { ReactComponent as Order } from "../../../Svg/GeneralMenu/order.svg";
import axios from "axios";
import { baseTheme } from "src/Style/baseTheme";
import { Button } from "src/Components/Button";
import { Title } from "src/Components/Title";
import { ReceiptScreen } from "src/Components/ReceiptScreen";


export const Notifications = () => {
    const navigate = useNavigate();
    const [imageSrc, setImageSrc] = useState(null)
    const [showImage, setShowImage] = useState(false);
    const [notificationsData, setNotificationsData] = useState(null); 
    
  const authToken = localStorage.getItem('authToken');
  const fetchDataHistory = useCallback(async (apiData) => {
      
      try {
      const response = await axios.get(`${UrlHistory}${"?limit=1"}&${`order_id=${apiData.extra.order_id}`}`,   { 
          headers: {
              'Authorization': `Bearer ${authToken}`
          }});
          
      const OperationDetails = Object.values(response)[0].data[0];
          
      navigate('/operation_details', { state: { OperationDetails } });
      } catch (error) {
      console.error("Ошибка при получении данных:", error);
      }
  }, [authToken, navigate]);

  const fetchDataNext = useCallback(async (apiData) => {
    
          try {
              const response = await axios.get(`${UrlMain}get_ready?${`order_type=${apiData.extra.order_type}`}&${`order_id=${apiData.extra.order_id}`}`, {
                  headers: {
                      'Authorization': `Bearer ${authToken}`
                    }
                });
              fetchDataHistory(apiData)
          } catch (error) {
              console.error("Ошибка при получении данных:", error);
          }
  }, [authToken, fetchDataHistory]);

  const fetchDataImg = useCallback(async (apiData) => {
      if (apiData?.extra?.path) {
          try {
              const response = await axios.get(`${UrlMain}img/${apiData?.extra?.path}`, {
                  responseType: 'blob',
                  headers: {
                      'Authorization': `Bearer ${authToken}`
                    }
                });
              const imageUrl = URL.createObjectURL(response.data);
              setImageSrc(imageUrl);
              setShowImage(true);
          } catch (error) {
              console.error("Ошибка при получении данных:", error);
          }
      } else {
      }
  }, [authToken, fetchDataHistory]);

    const fetchData = useCallback(async () => {
    
        try {
        const response = await axios.get(`${UrlNotifications}${`?limit=80`}${`&page=0`}`, { 
            headers: {
            'Authorization': `Bearer ${authToken}`
          }});
        
        setNotificationsData(response.data.data);
        
        
        } catch (error) {
        console.error("Ошибка при получении данных:", error);
        }
    },[]);

    const NotifRead = useCallback(async () => {
        const notifIds = notificationsData
            .filter(notification => !notification.is_read)
            .map(notification => notification.id);
    
        for (const id of notifIds) {
            try {
                const response = await axios.post(UrlNotifRead, 
                    { notif_id: id },
                    { headers: {
                    'Authorization': `Bearer ${authToken}`
                  }
                });
                
            
            } catch (error) {
                console.error("Ошибка при обновлении статуса уведомления с ID:", id, error);
            }
        }
    }, [notificationsData]); 
    
    const getIcon = (item) => {
        if (item?.record_type === "refresh_balance") return <styled.ItemSvg  color='#EECC55'><Dollar /></styled.ItemSvg>;
        if (item?.record_type === "exchange") return <styled.ItemSvg color='#55ACEE'><Repeat /></styled.ItemSvg>;
        if (item?.record_type === "end_card") return <styled.ItemSvg color='#FF8A71'><Replenishment /></styled.ItemSvg>;
        if (item?.record_type === "transfer") return <styled.ItemSvg color='#965E91'><Transfer /></styled.ItemSvg>;
        if (item?.record_type === "sms") return <styled.ItemSvg color={`${baseTheme.colors.operation}`}><Smartphone /></styled.ItemSvg>;
        if (item?.record_type === "new_card") return <styled.ItemSvg color={`${baseTheme.colors.headerMain}`}><Сard /></styled.ItemSvg>;
        if (item.record_type === "payfor") return <styled.ItemSvg color='#7769b4' tresh={true}><Order /></styled.ItemSvg>;
        return null;
    };

      const handleClose = () => {
        setShowImage(false);
        setTimeout(() => setImageSrc(null), 500); 
      };

    useEffect(() => {
        if (notificationsData) {
            NotifRead();
        }
    }, [notificationsData, NotifRead]);
    useEffect(() => {
        fetchData()
    }, [fetchData])
    return(
    <>
        <styled.Container>
            <Back continuation={false} Title={undefined}/>
            <Title Title={"Уведомления"} />
            <styled.Notifications>
                {notificationsData?.map((item, index) => {
                    console.log(item);
                    const parts = item.label.split("  ");
                    return(
                    <styled.Item key={item?.id}> 
                        <styled.Main>
                            <styled.Wrapper>
                                <styled.Icon>
                                {getIcon(item)}
                                </styled.Icon>
                                <styled.Title>
                                {/* {(item?.record_type === "exchange" ) && (!!item?.extra?.path) && item?.label} */}
                                {parts.map((text) =>{
                                    return(
                                        <div>
                                        { text}
                                        </div>
                                    )
                                })}
                                
                                </styled.Title>
                            </styled.Wrapper>
                        </styled.Main>
                        {(item?.record_type === "exchange")&& <styled.TextWrapper>
                            {item?.extra?.text &&
                                <styled.Text>
                                {item?.extra?.text}
                            </styled.Text>}
                            { (!!!item?.extra?.path) && (!!!item?.extra?.get_ready)&& 
                                <styled.ButtonWrapper onClick={() => fetchDataNext(item)}>
                                    <Button Title={"Готов получить"}  active={true} color={true}/>
                                </styled.ButtonWrapper> 
                            } 
                            {(!!item?.extra?.path) ? 
                            <styled.ButtonWrapper onClick={() => fetchDataImg(item)}>
                                <Button Title={"Данные для получения"}  active={true} color={true}/>
                            </styled.ButtonWrapper>
                            : null
                            }
                        </styled.TextWrapper>}
                        {item?.record_type === "payfor" && item?.extra?.link && item?.extra?.comment &&
                        <styled.TextWrapper>
                            <styled.Text>
                                {item?.extra?.link}
                            </styled.Text>
                            <styled.Text>
                                {item?.extra?.comment}
                            </styled.Text>
                        </styled.TextWrapper>}
                        <styled.Data>
                            <styled.Text>
                                {item?.date_create}
                            </styled.Text>
                             {item?.record_type === "payfor" &&
                             <styled.Text>
                                {item?.extra?.text}
                            </styled.Text>}
                        </styled.Data>
                    </styled.Item>
                )})}
            </styled.Notifications>
        </styled.Container>
        {imageSrc && <ReceiptScreen img={imageSrc} show={showImage} onClose={handleClose} />}
    </>
)}

