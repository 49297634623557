import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 65px 20px;
  position: relative;
`;

export const BackWrapper = styled.div`
`;
export const Header = styled.h1`

`
export const Text = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`