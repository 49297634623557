import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";


export const Success = styled.div` 
    & svg{
        margin-bottom: 30px;
    }
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 145PX 20px 45PX;
    position: relative;
`;
export const Title = styled.h1`
    font-size: 6ch;
    margin-bottom: 20px;
    color: ${baseTheme.colors.operation};
`;
export const SubTitle = styled.div`
    font-size: 42px;
    color: ${baseTheme.colors.text};
    margin-bottom: 20px;
`;
export const Text = styled.div`
    font-size: 18px;
    color: ${baseTheme.colors.text};
`;
export const MainButtonWrapper = styled.div`
    margin-left: 20px;
`;