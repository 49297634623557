import styled, { css } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const DebitingWrapper = styled.div<{disable?: boolean}>`
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
    padding: 18px 0;
    ${(props) => props.disable && css`
        opacity:0.5;
    `}
`;
export const DebitingWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const DebitingText = styled.div`
    font-size: 16px; 
`;
export const RollerWrap = styled.div <{toggled: boolean}>`
    position: relative;
    padding: 5px;
    width: 36px;
    height: 20px;
    border-radius: 30px;
    background: #374B8E;
    ${(props) => props.toggled && css`
        background: ${baseTheme.colors.link};
        ${Roller}{
            left: calc(100% - 16px);
            transition: transform 0.3s ease;
        }
    `}
`;
export const Roller = styled.div`
    position: absolute; 
    transition: transform 0.3s ease;
    left:2px;
    top: 3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${baseTheme.colors.standard};
`;