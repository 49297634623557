import styled from "styled-components";

export const Container = styled.div`
  padding: 0 20px 100px 20px;
  z-index: 1;
`;

export const Title = styled.div`
  margin-bottom : 30px;
`
