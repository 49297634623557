import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

export const Button = styled.div<{
  active: boolean;
  disable: boolean;
  color: boolean;
}>`
  display: flex;
  gap: 10px;
  padding: 8px 20px;
  background: ${baseTheme.colors.standard};
  border-radius: 10px;
  width: max-content;
  box-shadow: 2px 2px 2px ${baseTheme.colors.boxShadow};
  ${(props) =>
    props.active &&
    css`
      background: ${baseTheme.colors.link};
      ${Title} {
        color: ${baseTheme.colors.standard};
      }
      ${Img} {
        color: ${baseTheme.colors.standard};
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
          rect{
            fill: #fff;
          }
        }
      }
    `}
  ${(props) =>
    props.disable &&
    css`
      opacity: 0.5;
    `}
  ${(props) =>
    props.color &&
    css`
      background: linear-gradient(${baseTheme.colors.mainButton});
      justify-content: center;
      width: 100%;
      box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
      ${Title} {
        color: ${baseTheme.colors.standard};
      }
    `}
`;
export const Img = styled.div`
  width: 20px;
  height: 20px;
  & svg {
    width: 20px;
    height: 20px;
    fill: ${baseTheme.colors.bodyText};
  }
`;
export const Title = styled.div`
  font-size: 16px;
`;
