import React from "react";
import * as styled from "./Currency.style";

interface CurrencyProps {
  FlagIcon?: any, 
  title?: string,
  label?: string,
  width?: boolean,
}



export const Currency : React.FC<CurrencyProps> = ({ FlagIcon, title, label, width }) => {
  return (
    <styled.TitleWrap width={width}>
      <styled.Flag>
        <FlagIcon />
      </styled.Flag>
      <styled.OriginWrapp>
        <styled.Title>{title !== "ЮMONEY" ? title : "RUB"}</styled.Title>
        {!width && <styled.Origin>{label}</styled.Origin>}
      </styled.OriginWrapp>
    </styled.TitleWrap>
  );
};


