import React from 'react';
import * as styled from "./Loader.style";

export const LoadingScreen = () => (
  <styled.LoadingWrapper>
    Rocket
    <styled.Loader />
  </styled.LoadingWrapper>
);

