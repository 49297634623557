import React from "react";

import * as styled from "./CardSlide.style";

import { ReactComponent as Visa } from "src/Svg/CardFirm/visa.svg";

interface ButtonProps {
  firmCard: string;
  active?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

export const CardSlide: React.FC<ButtonProps> = ({
  active,
  disable,
  onClick,
  firmCard,
}) => {
  return (
    <styled.Button disable={disable} active={active} onClick={onClick}>
      <styled.Card>
        <styled.Img>
          <Visa />
        </styled.Img>
      </styled.Card>
      <styled.Info>
        <styled.CardNumber>**** **** **** 7895</styled.CardNumber>
        <styled.CardBalance>4 863.27 USD</styled.CardBalance>
      </styled.Info>
    </styled.Button>
  );
};
