import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as styled from "./History.style";
import { HistoryList } from "../../HistoryList";
import { UrlHistory } from "../../../Static/Url";
import { ButtonsFilter } from "../../ButtonsFilter";
import axios from "axios";
import { Back } from "src/Components/Back";
import { Title } from "src/Components/Title";
import { useFetchData } from "../../../hooks/useFetchData";


export const History = () => {
  
  const [order, setOrder] = useState("");
  const orderUrl = useMemo(() => order ? `order_types=${order}` : null, [order]);

  const url = `${UrlHistory}?limit=80&group_by=date&${orderUrl}`;
  const { data: apiData, isLoading } = useFetchData(url);


  return (
      <styled.Container>
        <styled.HistoryWrapper>
          <styled.HistoryWrap>
            <Back Title={""} continuation={false}/>
          </styled.HistoryWrap>
          <Title Title={"Последние операции"} />
          <ButtonsFilter setOrder={setOrder} />
          <styled.HistoryBlock>
            {isLoading ? (
              <>
                <styled.SkeletonWrapper>
                    <styled.SkeletonElement className="title" />
                   { Array.from({ length: 5 }, (_, index) => (
                        <React.Fragment key={index}>
                        <styled.SkeletonWrapperHistory >
                            <styled.SkeletonElement className="iconHistory" />
                            <styled.SkeletonElement className="titleHistory" />
                            <styled.SkeletonElement className="subtitleHistory" />
                            <styled.SkeletonElement className="amountHistory" />
                        </styled.SkeletonWrapperHistory >
                        </React.Fragment>
                        ))}
                </styled.SkeletonWrapper>
              </>
            ) : (
              apiData?.data?.map((item, index) => (
                <>
                  <styled.HistoryData>
                    {Object.values(item)[0]}
                  </styled.HistoryData>
                  <HistoryList historyData={item?.rows} />
                </>
              ))
            )}
          </styled.HistoryBlock>
        </styled.HistoryWrapper>
      </styled.Container>
  );
};