import React, { useState } from "react";
import * as styled from "./Accordion.style";
import backIcon from "../../Svg/chevron-left.svg"; 

interface AccordionProps { 
  items: { id: number; question: string; answer: string }[];
  lastElementRef: (node: HTMLDivElement | null) => void;
}

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      {items.map((item, index) => (
        <styled.AccordionItem key={item.id}>
          <styled.AccordionHeader
            isOpen={openIndex === index}
            onClick={() => toggleAccordion(index)}
          >
            {item.question}
            <styled.ArrowIcon
              src={backIcon}
              alt="Иконка открыть"
              isOpen={openIndex === index}
            />
          </styled.AccordionHeader>
          {openIndex === index && (
            <styled.AccordionContent isOpen={openIndex === index}>
              {item.answer}
            </styled.AccordionContent>
          )}
        </styled.AccordionItem>
      ))}
    </>
  );
};
