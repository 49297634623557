import React, { useEffect } from 'react'
import * as styled from './OMEP.style'
import { useNavigate } from 'react-router-dom'
import { useResize } from 'src/hooks/useResize'

export const OnlyMobileErrorPage = () => {
    const navigate = useNavigate()
    const {width, isScreenMedium} = useResize()
    console.log(width, isScreenMedium)
    useEffect(()=> {
      if (localStorage.getItem('authToken') && !isScreenMedium){
        navigate('/')
      }
    }, [isScreenMedium])
  return (
    <styled.Container>
        {/* <styled.Image>
        </styled.Image> */}
        <h1>
            Похоже вы вышли за пределы нашей вселенной. Для продолжения зайдите 
            с мобильной версии приложения, либо через <a href='https://t.me/approcket_bot' target='_blank' style={{color:'white', textDecorationColor:'white', textDecorationLine:'underline', cursor:'pointer'}}> телеграмм бот </a>
        </h1>
    </styled.Container>
  )
}

