import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {useToast} from "../../useToast/ToastContext";
import "react-toastify/dist/ReactToastify.css";
import * as styled from "./Receiving.style";
import { useLocation } from "react-router-dom";
import { UrlMain, UrlRates, UrlPaymentLink } from "../../../Static/Url";
import { ReactComponent as Info } from "../../../Svg/Info.svg";
import { ReactComponent as Atm } from "../../../Svg/Receiving/atm.svg";
import { ReactComponent as Shop } from "../../../Svg/Receiving/shop.svg";
import { ReactComponent as Vespa } from "../../../Svg/Receiving/vespa.svg";
import { MainButton } from "src/Components/MainButton";
import { Back } from "src/Components/Back";
import { Manual } from "src/Components/Manual";
import { Button } from "src/Components/Button";
import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { currencyData } from "src/Static/SelectedCurrencyData";
import { Title } from "src/Components/Title";
import { BalanceInput } from "../../BalanceInput";
import { AmountDisplay } from "src/Components/AmountDisplay";
import { ReactComponent as Rp } from "src/Svg/Flag/RpFlag.svg";
import { DebitingSection } from "src/Components/DebitingSection";
import { ReactComponent as UndefindedFlag } from "../../../Svg/Flag/undefindedFlag.svg";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const Receiving = () => {
  const location = useLocation();
  const { activeCurrency } = location.state || {};
  
  const [toggled, setToggled] = useState(false);
  const [toggledAmount, setToggledAmount] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [symbol, setSymbol] = useState<any>(UndefindedFlag);
  const [isTitle, setIsTitle] = useState<any>("none");
  const [isSubtitle, setIsSubtitle] = useState<any>("none");
  const [selectedReceiving, setSelectedReceiving] = useState();
  const [isManual, setisManual] = useState([]);
  const [isCommission, setIsCommission] = useState("7");
  const [apiData, setApiData] = useState(null);
  const [inputValue, setInputValue] = useState<any>("0");
  const [isToastActive, setIsToastActive] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [isdisabled, setIsdisabled] = useState(false);
  const [redirectData, setRedirectData] = useState(null);

  const showToast = useToast(); 

  const handleCurrencyClick = (currency) => {
    if (!currency.disabled) {
      setSymbol(() => currency.symbol);
      setIsTitle(currency.title);
      setIsSubtitle(currency.subtitle);
      setSelectedCurrency(currency.code);
    }
  };

  const authToken = localStorage.getItem("authToken");
  const rate =
  Number(apiData?.find((item) => item.name === selectedCurrency)?.rate.replace(/\s/g, '')) || 0;
  const result = rate * inputValue.replace(/\D/g, "") || 0.00;

  const HandelToggled = useCallback(() => {
    setToggled((prev) => !prev);
  }, []);


  const ToggledData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${UrlMain}/get_from_balance?user_id=1&output_currency=IDR`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setToggledAmount(response.data.amount);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, []);

  const ReceivingData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${UrlMain}exchange_type?exchange_type=${selectedReceiving}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setisManual(Object.values(response.data));
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, [selectedReceiving]);

  const CommissionData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${UrlMain}commission`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setIsCommission(response?.data?.commission_value);
      
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoad(false);
    try {
      const response = await axios.get(`${UrlRates}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setApiData(response.data);
      setIsLoad(true);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, [authToken]);

  const getCurrencySymbol = (currency) => {
    const symbols = {
      RUB: "₽",
      USD: "$",
      EUR: "€",
      USDT: "₮",
      YOO:"₽"
    };
    return symbols[currency] || "?";
  };
  console.log('Recieving', selectedCurrency)
  const handleReceivingClick = (currency) => {
    setSelectedReceiving(currency);
  };

  const addSpaces = (num) => {
    if (!num) return num;
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
  };

  const showToastMessage = (message) => {
    if (isToastActive) {
      return;
    }
    console.log('showToastMEssage rendered')
    setIsToastActive(true);
    showToast(message)
    setTimeout(() => {
      setIsToastActive(false);
    }, 5000);
  };

  const handleInputFocus = useCallback(() => {
    if (inputValue === "0" || inputValue === "") {
      setInputValue("");
    }
  }, [inputValue]);
  const [err, setErr] = useState('')
  axiosRetry(axios, {
    retries:10,
    retryDelay: (retryCount) => {
      console.log('retry attemt : ', retryCount)
      return retryCount * 2000
    },
    retryCondition:(error) => {
      return error.code === 'ERR_NETWORK'
    }
  })
  const onMainButtonClicked = () => {
    const response = HandleMainButton()
    toast.promise(response, {
      pending:'Ожидайте завершения операции',
      success:'Выполнено успешно'
    })
  }
  const HandleMainButton = useCallback(async () => {
    if (isToastActive) {
      return;
    }
    
    if (
      selectedCurrency === undefined ||
      selectedCurrency === null ||
      selectedCurrency === "Questions" ||
      !selectedReceiving
    ) {
      let message;
      selectedCurrency === undefined || 
      selectedCurrency === null || 
      selectedCurrency === "Questions"
        ? (message = "Выберете валюту")
        : (message = "Выберете способ получения средств");
      showToastMessage(message);
      return;
    }
    if (inputValue === "") {
      setInputValue("0");
      return
    }
    if (Number(result.toFixed(2)) < 100000) {
      showToastMessage("Сумма слишком маленькая");
      return
    }
    if (Number(result.toFixed(2)) > 1000000) {
      showToastMessage("Сумма слишком большая");
      return
    }

    if (Number(result.toFixed(2)) > 1000000) {
      showToastMessage("Сумма слишком большая");
      return;
    }
    setIsToastActive(true);
    
    setIsdisabled(true)
    
    
    try {
      const body = {
        user_currency: selectedCurrency.toString(),
        user_amount: inputValue.replace(/\D/g, "").toString(),
        output_currency: "IDR",
        output_amount: result.toFixed(2).toString(),
        order_type: "exchange",
        write_off_balance: toggled,
        exchange_type: selectedReceiving,
      };

      const response = await toast.promise(axios.post(UrlPaymentLink, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      }), {
            pending:'Ожидайте завершения операции',
            success:'Выполнено успешно'
        }, {
          pauseOnFocusLoss:false
        }
      );
      
      setRedirectData({
        orderId: response.data.order_id,
        orderType: response.data.order_type,
        url: response?.data.url,
      });
  
      if (!response?.data.url) {
      showToast(`${response.data.message}`)
    }
    } catch (error) {
      const err = error as AxiosError
      if (err){
        console.log(err)
      }
      
    } finally {
      setIsdisabled(true)
          setTimeout(() => {
              setIsToastActive(false);
          }, 5000);
      }
  }, [
    isToastActive, 
    selectedCurrency, 
    inputValue, 
    result, 
    toggled,
    selectedReceiving, 
    authToken
  ]);

  const handleSaveAndRedirect = (orderId, orderType, url) => {
    localStorage.setItem("orderId", orderId);
    localStorage.setItem("orderType", orderType);
  
    const savedOrderId = localStorage.getItem("orderId");
    const savedOrderType = localStorage.getItem("orderType");
    console.log(localStorage.getItem("orderId"));
    console.log("Сохраненный orderType:", savedOrderType);
  
    if (localStorage.getItem("orderId") && localStorage.getItem("orderType")) {
      window.location.href = url;
    }
  };
  
  useEffect(() => {
    if (toggled) {
      ToggledData();
    }
  }, [ToggledData, toggled]);
  useEffect(() => {
      CommissionData();
  }, [CommissionData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedReceiving !== undefined) {
      ReceivingData();
    }
  }, [ReceivingData, selectedReceiving]);

  useEffect(() => {
    if (activeCurrency) {
      setSelectedCurrency(activeCurrency);
    }
  }, [activeCurrency]);

  useEffect(() => {
    const activeCurrencyObj = currencyData.find(
      (c) => c.code === activeCurrency
    );

    if (activeCurrencyObj) {
      handleCurrencyClick(activeCurrencyObj);
    }
  }, []);
  useEffect(() => {
    if (redirectData && redirectData.orderId && redirectData.orderType && redirectData.url) {
      handleSaveAndRedirect(redirectData.orderId, redirectData.orderType, redirectData.url);
    }
  }, [redirectData]);
  useEffect(() => {
    localStorage.removeItem("orderId");
    localStorage.removeItem("orderType");
  }, []);
  return (
    <styled.Receiving>
      <styled.Container>
        <styled.BackWrapper>
          <Back Title={""} continuation={true} />
        </styled.BackWrapper>
        <Title Title={"Получение наличных"} />
        <styled.SubTitle>Выбор валюты</styled.SubTitle>
        <styled.ReceivingWrapper>
          <Swiper spaceBetween={10} slidesPerView={"auto"}>
            {currencyData
              .filter((currency) => currency.code !== "Question")
              .map((currency) => (
                <SwiperSlide key={currency.title}>
                  <Button
                    Title={currency.title}
                    active={selectedCurrency === currency.code}
                    onClick={() => handleCurrencyClick(currency)}
                    disable={currency.disabled}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </styled.ReceivingWrapper>
        <styled.SubTitle>Выбор способа получения наличных</styled.SubTitle>
        <styled.ReceivingWrapper>
          <Swiper spaceBetween={10} slidesPerView={"auto"}>
            <SwiperSlide>
              <Button
                Svg={Shop}
                Title={"Indomaret"}
                active={selectedReceiving === "Indomaret"}
                onClick={() => handleReceivingClick("Indomaret")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Button
                Svg={Shop}
                Title={"Alfamart"}
                active={selectedReceiving === "Alfamart"}
                onClick={() => handleReceivingClick("Alfamart")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Button
                Svg={Shop}
                Title={"GoPay"}
                active={selectedReceiving === "GoPay"}
                onClick={() => handleReceivingClick("GoPay")}
              />
              </SwiperSlide>
            <SwiperSlide>
              <Button
                Svg={Atm}
                Title={"Банкомат"}
                active={selectedReceiving === "Банкомат"}
                disable={false}
                onClick={() => handleReceivingClick("Банкомат")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Button
                Svg={Vespa}
                Title={"Курьер"}
                active={selectedReceiving === "Курьер"}
                disable={true}
              />
            </SwiperSlide>
          </Swiper>
        </styled.ReceivingWrapper>
        <styled.SubTitle>Сумма: с учетом комиссии {addSpaces(Math.ceil(+inputValue.replace(/\D/g, "")+((+inputValue.replace(/\D/g, "") * +isCommission)/100)))} {getCurrencySymbol(selectedCurrency)} комиссия {isCommission}%</styled.SubTitle>
        <styled.AmountWrapper>
          <BalanceInput
            value={inputValue}
            onChange={(e) =>
              setInputValue(
                e.target.value.replace(getCurrencySymbol(selectedCurrency), "")
              )
            }
            symbol={symbol}
            disabled={!selectedCurrency}
            onFocus={handleInputFocus}
            name={isTitle}
            subtitle={isSubtitle}
          />
        </styled.AmountWrapper>
        <styled.SubTitle>Сумма к получению:</styled.SubTitle>
        <AmountDisplay
          Symbol={Rp}
          title={"IDR"}
          label={"Rupiah"}
          Number={
            toggledAmount
              ? addSpaces(toggledAmount + result)
              : result
              ? addSpaces(result.toFixed(2))
              : "0"
          }
        />
        {/* <DebitingSection toggled={toggled}  onClick={HandelToggled}>/> */}
        {/* <DebitingSection toggled={toggled} /> */}
        <styled.SubTitleInfoWrapper>
          <styled.SubTitleInfo>
            <styled.SubTitleInfoText>
              <Link to={"/instructions"}>
              Как получить наличные инструкция для банкомата
              </Link>
            </styled.SubTitleInfoText>
            <Info />
          </styled.SubTitleInfo>
        </styled.SubTitleInfoWrapper>
        {isManual.length > 0 && <Manual Manual={isManual} />}
        <styled.MainButtonWrapper onClick={HandleMainButton}>
          <MainButton title={"Получить наличные"} />
        </styled.MainButtonWrapper>
      </styled.Container>
    </styled.Receiving>
  );
};
