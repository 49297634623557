import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import { GlobalStyle } from './Style/theme';
import { useResize } from './hooks/useResize';
import { LoadingScreen } from './Components/Loader';
import { Warning } from './Components/Warning';
import { Background } from './Components/Background';
import { MainPage } from './Components/Pages/MainPage';
import {TelegramAuthPage} from './Components/Pages/TelegramAuth/TgAuth';
import {OnlyMobileErrorPage} from './Components/Pages/OnlyMobileError/OnlyMobileErrorPage';
import { History } from './Components/Pages/History';
import { Exchange } from './Components/Pages/Exchange';
import { Notifications } from './Components/Pages/Notifications';
import { PrivacyPolicy } from './Components/Pages/PrivacyPolicy';
import { Receiving } from './Components/Pages/Receiving';
import { Success } from './Components/Pages/Success';
import { OperationDetails } from './Components/Pages/OperationDetails';
import { NewCard } from './Components/Pages/NewCard';
import { MoneyBox } from './Components/Pages/MoneyBox';
import { ExtraDetails } from './Components/Pages/ExtraDetails';
import { ExtraPage } from './Components/Pages/ExtraPage';
import { RechargeCard } from './Components/Pages/RechargeCard';
import { FaqPage } from './Components/Pages/FaqPage';
import { OrderProduct } from './Components/Pages/OrderProduct';
import { Reviews } from './Components/Pages/Reviews';
import {InstructionsRecieving} from './Components/Pages/Instructions/InstructionsRecieving'
import { GeneralMenu } from './Components/GeneralMenu';
import { UrlMain } from './Static/Url';
import axios from 'axios';
import { ToastProvider } from './Components/useToast/ToastContext';
import Language from './Components/Pages/Language/Language';

export const App = () => {
  const tg = window.Telegram.WebApp
  tg.expand()
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isUserTg = window.Telegram.WebApp.initDataUnsafe;
  const navigate = useNavigate()
  const {width, isScreenMedium} = useResize()
  // const fakeUser='query_id=AAHgmhgQAAAAAOCaGBBtDCmn&user=%7B%22id%22%3A270047968%2C%22first_name%22%3A%22Konstantin%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22stansxnn%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1724074555&hash=ea5fadd0390f032711df918500c2436acea15f47430410ef0c5dcaf96010429b'
  const initialize = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        // `https://api-rocket.terrapay.online/api/auth/${fakeUser}`
        `${UrlMain}auth/${window.Telegram.WebApp?.initData}`
      );
      const token = response.headers['authorization'];
      localStorage.setItem('authToken', token);
      setAuthToken(token);
    } catch (error) {
      console.error('Ошибка при получении данных в инициализации в тг:', error);
    } finally {
      setIsLoading(false);
      window.Telegram.WebApp.ready(); 
    }
  }
  const fetchTgAuth = async () => {
    // console.log('tgUserFromLocalStorage =', localStorage.getItem('tgUser'))
    if(localStorage.getItem('tgUser')){
      try {
        const response = await axios.post(`${UrlMain}auth`, 
          localStorage.getItem('tgUser'),
          {
            headers:{
              'Content-Type': 'application/json'
            },
          })
          console.log('RESPONSE.headers = ', response.headers)
          const token = response.headers['authorization'].replace('Bearer ','');
          console.log('TOken = ', token)
          localStorage.setItem('authToken', token);
          setAuthToken(token);
          console.log('Token in fetchTgAuth = ', token)
          const data = (await response.json())
          console.log('fetchTgAuth = ', data)
        } catch (error){
          console.log(error)
        } finally {
          setIsLoading(false)
          tg.ready()
        }
      }
  }
  let user = localStorage.getItem('tgUser')
  useEffect(() => {
    // console.log(`${UrlMain}auth/${window.Telegram.WebApp?.initData}`);
    // console.log('Стринга которая используется в запросе /api/auth = ', tg.initData)
    if(tg.initData){
      console.log('Запускается инициализация в тг по стринге')
      initialize()
    } else if (user){
      console.log('Запускается авторизация с помощью тг-виджета')
      fetchTgAuth()
    } else {
      setIsLoading(false)
      tg.ready()
      console.log('Нет данных для авторизация пользователя. Отправляю на авторизация через тг-виджет')
      navigate('/telegram_auth')
    }
  }, [user]);
  
  useEffect(()=> {
    if(isScreenMedium){
      navigate('/only_mobile')
      console.log('redirect works. WIdth =', window.screen.width)
    }
  }, [isScreenMedium])
  
  // useEffect(()=> {
  //   if((isUserTg.user?.username)&&(isUserTg.user?.id)){
  //     localStorage.setItem('tgUser', '')
  //     console.log('User does not exist. Creating local storage properties')
  //   }
  //   if (!localStorage.getItem('tgUser')) {
  //     navigate("/telegram_auth")
  //   }
  // },[isUserTg])


  useEffect(() => {
    const backButton = window.Telegram.WebApp.BackButton;

    const onBackButtonClick = () => {
      console.log('backButtonClicked');
      setShowModal(true);
    };

    backButton.onClick(onBackButtonClick);
    backButton.show();

    return () => {
      backButton.offClick(onBackButtonClick);
    };
  }, []);

  const handleConfirmClose = () => {
    window.Telegram.WebApp.close();
  };

  const handleCancelClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Вы уверены, что хотите покинуть эту страницу?';
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }
console.log(location.pathname)
  return (
    <ToastProvider>
      <div className="App">
        <GlobalStyle />
        {location.pathname !== '/' &&
        location.pathname !== "/only_mobile" &&
        location.pathname !== "/telegram_auth" &&
        <Background />}
        {showModal && (
          <Warning onConfirm={handleConfirmClose} onCancel={handleCancelClose} />
        )}
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/language" element={<Language/>}/>
          <Route path="/telegram_auth" element={<TelegramAuthPage/>} />
          <Route path='/only_mobile' element={<OnlyMobileErrorPage/>}/>
          <Route path='/instructions' element={<InstructionsRecieving/>}/>
          <Route path="/history" element={<History />} />
          <Route path="/exchange" element={<Exchange />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/receiving" element={<Receiving />} />
          <Route path="/success_payment*" element={<Success />} />
          <Route path="/operation_details" element={<OperationDetails />} />
          <Route path="/new_card" element={<NewCard />} />
          <Route path="/money_box" element={<MoneyBox />} />
          <Route path="/extra" element={<ExtraPage />} />
          <Route path="/extra_details" element={<ExtraDetails />} />
          <Route path="/recharge_card" element={<RechargeCard />} />
          <Route path="/faq_page" element={<FaqPage />} />
          <Route path="/order_product" element={<OrderProduct />} />
          <Route path="/reviews" element={<Reviews />} />
        </Routes>
        {(location.pathname !== '/only_mobile') &&
        location.pathname !== "/telegram_auth" && 
        <GeneralMenu />
        }
      </div>
    </ToastProvider>
  );
};

export default App;