import React from "react";
import * as styled from "./ReviewItem.style";

const Star = ({ filled }) => (
  <svg width="20" height="20" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg">
    {/* Фон */}
    <rect width="100%" height="100%" fill="#3EB290" />
    {/* Звезда */}
    <path fill={filled ? '#FFFFFF' : '#E1E1E1'} d="M90.561,36.425c-0.332-1.037-1.295-1.742-2.385-1.742H58.514L48.281,3.525   C47.941,2.496,46.982,1.8,45.9,1.8s-2.043,0.697-2.381,1.725L33.287,34.683H3.625c-1.09,0-2.055,0.705-2.387,1.742   c-0.33,1.039,0.047,2.172,0.934,2.805l24.004,17.086l-9.662,29.41c-0.338,1.027,0.023,2.156,0.895,2.799   c0.871,0.641,2.057,0.648,2.938,0.023L45.9,70.356l25.551,18.191c0.436,0.309,0.943,0.465,1.453,0.465   c0.521,0,1.043-0.164,1.484-0.488c0.871-0.643,1.232-1.771,0.896-2.799l-9.66-29.41l24.004-17.086   C90.516,38.597,90.893,37.464,90.561,36.425z" />
  </svg>
);

export const ReviewItem = ({ review }) => {
  const { consumer, dates, rating, title, text } = review;

  return (
    <styled.ReviewContainer>
      <styled.ReviewHeader>
        <styled.StarRating>
          {Array.from({ length: 5 }, (_, i) => (
            <Star key={i} filled={i < rating} />
          ))}
        </styled.StarRating>
        <styled.ReviewerName>{consumer.displayName}</styled.ReviewerName>
        <styled.ReviewDate>{new Date(dates.publishedDate).toLocaleDateString()}</styled.ReviewDate>
      </styled.ReviewHeader>
      <styled.ReviewTitle>{title}</styled.ReviewTitle>
      <styled.ReviewText>{text}</styled.ReviewText>
    </styled.ReviewContainer>
  );
};
