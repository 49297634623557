import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import * as styled from "./PrivacyPolicy.style";
import { Back } from "src/Components/Back";
import { UrlPrivacyPolicy } from "../../../Static/Url";
import { Title } from "src/Components/Title";


export const PrivacyPolicy = () => {

    const [privacyText, setPrivacyText] = useState("");
    const [isLoading, setIsLoading] = useState(true); 

    
    const authToken = localStorage.getItem('authToken');

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${UrlPrivacyPolicy}`,{ headers: {
                'Authorization': `Bearer ${authToken}`
              }});
            setPrivacyText(response.data.text);
            setIsLoading(false);
            console.log(response);
            console.log(response.data.text);
            
        } catch (error) {
            console.error("Error fetching privacy policy:", error);
            setIsLoading(false);
        }
    }, [authToken]);
         
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <styled.Section>
            <styled.Container>
                <styled.BackWrapper>
                    <Back continuation={false} Title={''}/>
                </styled.BackWrapper>
                <Title Title={"Правила и условия"} />
                <styled.PrivacyUl>
                    <styled.PrivacyLi>
                        {isLoading ? (
                            <styled.SkeletonLoader />
                        ) : (
                            <styled.PrivacyText
                                dangerouslySetInnerHTML={{ __html: privacyText }}
                            />
                        )}
                    </styled.PrivacyLi>
                </styled.PrivacyUl>
            </styled.Container>
        </styled.Section>
    )
};