import React, { useCallback, useEffect, useRef, useState } from "react";
import { Back } from "src/Components/Back";
import {useToast} from "../../useToast/ToastContext";
import * as styled from "./OrderProduct.style";
import { Title } from "src/Components/Title";
import { Subtitle } from "src/Components/Subtitle";
import { Search } from "src/Components/Search";
import { SwiperSlide, Swiper } from "swiper/react";
import { Button } from "../../Button";
import { BalanceInput } from "../../BalanceInput";
import { currencyData, flagData } from "../../../Static/SelectedCurrencyData";
import { ReactComponent as Question } from "src/Svg/Flag/undefindedFlag.svg";
import { ReactComponent as Rp } from "src/Svg/Flag/RpFlag.svg";
import { UrlMain, UrlRates, UrlPaymentLink } from "../../../Static/Url";

import { CommentInput } from "src/Components/CommentInput";
import { MainButton } from "../../MainButton";
import { AmountDisplay } from "../../AmountDisplay";
import axios from "axios";

export const OrderProduct: React.FC = () => {
  
  const authToken = localStorage.getItem("authToken");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCurrencyPay, setSelectedCurrencyPay] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [currentSymbol, setCurrentSymbol] = useState(Question);
  const [currentPaySymbol, setCurrentPaySymbol] = useState(Question);
  const [title, setTitle] = useState("none");
  const [subTitle, setSubTitle] = useState("none");
  const [titlePay, setTitlePay] = useState("none");
  const [subTitlePay, setSubTitlePay] = useState("none");
  const [apiData, setApiData] = useState(null);
  const [amountValue, setAmountValue] = useState("0");
  const [inputValue, setInputValue] = useState("0");
  const [isCommission, setIsCommission] = useState("7");
  const [toggled, setToggled] = useState(false);
  const [comment, setComment] = useState("");
  const [isLoad, setIsLoad] = useState(true);
  const [isdisabled, setIsdisabled] = useState(false);
  const [isToastActive, setIsToastActive] = useState(false);
  
  const amountInputRef = useRef(null);

  const showToast = useToast(); 

  const rate =
    Number(apiData?.find((item) => item.name === selectedCurrency)?.rate.replace(/\s/g, '')) || 0;
    
  const ratePay =
  selectedCurrencyPay !== "USDT" ? Number(apiData?.find((item) => item.name === selectedCurrencyPay)?.rate.replace(/\s/g, '')) || 0 : Number(apiData?.find((item) => item.name === "USD")?.rate.replace(/\s/g, '')) || 1;
  const resultIDR = rate * +amountValue.replace(/\D/g, "") || 0;
  const result = ratePay !== 0 ? +resultIDR/ratePay : 0;
  const rateToIdr = Number(apiData?.find((item) => item.name === selectedCurrencyPay)?.rate.replace(/\s/g, '')) || 0;
  const AmountReplaced = +amountValue.replace(/\D/g, "") || 0
  const resultPaymentAmountIdr = rateToIdr!==0 ? AmountReplaced/rateToIdr : 0 
  console.log('resultPaymentFromIdr', resultPaymentAmountIdr)
  console.log('Amount', AmountReplaced)
  console.log('rateIDR', rateToIdr)
  console.log('resultIdr', resultIDR)
  console.log('result' ,result)
  console.log('Курс', rate);

  const handleFocus = (ref) => {
    if (ref.current) {
      const element = ref.current;
      const rect = element.getBoundingClientRect();
      const absoluteElementTop = rect.top + window.pageYOffset;
      
      const middle = absoluteElementTop - (window.innerHeight / 2) + (rect.height );
  
      window.scrollTo({
        top: middle,
        behavior: 'smooth'
      });
    }
  };

  const rateData = useCallback(async () => {
    setIsLoad(false);
    try {
      const response = await axios.get(`${UrlRates}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setApiData(response?.data);
      setIsLoad(true);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, []);

  
  const CommissionData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${UrlMain}commission`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setIsCommission(response?.data?.commission_value);
      
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  }, []);

  const getCurrencySymbol = (currency) => {
    console.log(currency);
    const symbols = {
      RUB: "₽",
      USD: "$",
      EUR: "€",
      USDT: "₮",
    };
    return symbols[currency] || "?";
  };

  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
  };


  function handleCurrencyClick(currency) {
    if (!currency.disabled) {
      setCurrentSymbol(currency.symbol);
      setSelectedCurrency(currency.code);
      setSubTitle(currency.subtitle);
      setTitle(currency.title);
      setAmountValue('0')
    }
  }
  function handleCurrencyPayClick(currency) {
    if (!currency.disabled) {
      setCurrentPaySymbol(currency.symbol);
      setSelectedCurrencyPay(currency.code);
      setSubTitlePay(currency.subtitle);
      setTitlePay(currency.title);
    }
  }

  const addSpaces = (num) => {
    if (!num) return num;
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleBalanceChange = (event) => {
    setAmountValue(event.target.value);
  };

  const formatNumber = (num) => {
    return num % 1 === 0 ? num.toString() : num.toFixed(2);
  };

  const HandleMainButton = useCallback(async () => {
    if (isToastActive) {
        return;
    }

    setIsToastActive(true);

    if (selectedCurrency === "") {
        showToast("Выберите валюту продукта");
        setIsToastActive(false);
        return;
    }

    if (parseInt(String(amountValue).replace(/\D/g, "")) === 0) {
        showToast("Введите сумму");
        setIsToastActive(false);
        return;
    }

    if (searchTerm === "") {
        showToast("Введите URL товара");
        setIsToastActive(false);
        return;
    }
    if (comment.trim() === "") {
        showToast("Введите комментарий");
        setIsToastActive(false);
        return;
    }

    if (selectedCurrencyPay === "") {
        showToast("Выберите валюту оплаты");
        setIsToastActive(false);
        return;
    }
    setIsdisabled(true)
    try {
        const body = {
            output_currency: selectedCurrency,
            output_amount: amountValue.replace(/\D/g, "").toString(),
            link_pay_for: searchTerm,
            comment: comment,
            user_currency: selectedCurrencyPay,
            user_amount: selectedCurrency !== "IDR" ? Math.ceil(result).toString() : Math.ceil(resultPaymentAmountIdr).toString(),
            order_type: "payfor",
            write_off_balance: false,
        };

        const response = await axios.post(UrlPaymentLink, body, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
        showToast("Ожидайте завершения операции");

        localStorage.setItem("orderId", response.data.order_id);
        localStorage.setItem("orderType", response.data.order_type);
        if (response?.data.url) {
            window.location.href = response.data.url;
        } else {
          showToast(`${response.data.message}`)
        }
    } catch (error) {
        console.error("Ошибка при получении данных:", error);
        showToast("Произошла ошибка при обработке вашего запроса");
    } finally {
      
    setIsdisabled(true)
        setTimeout(() => {
            setIsToastActive(false);
        }, 5000);
    }
}, [
    authToken,
    searchTerm,
    isUrlValid,
    selectedCurrency,
    amountValue,
    comment,
    selectedCurrencyPay,
    result,
    showToast
]);

  useEffect(() => {
    rateData()
  }, [rateData])
  
  useEffect(() => {
    CommissionData()
  }, [CommissionData])
  
  return (
    <>
      <styled.Container>
        <styled.BackWrapper>
          <Back continuation={true} Title={undefined} />
        </styled.BackWrapper>
        <Title Title={"Оплатим за вас "} />
        <styled.Wrapper>
          {false ? (
            <>
              <styled.Wrapper>
                <styled.SkeletonWrapper>
                  <styled.SkeletonElement className="title" />
                  <styled.SkeletonSwiperWrapper>
                    {Array.from({ length: 4 }, (_, index) => (
                      <React.Fragment key={index}>
                        <styled.SkeletonElement className="swiperItem" />
                      </React.Fragment>
                    ))}
                  </styled.SkeletonSwiperWrapper>
                </styled.SkeletonWrapper>
              </styled.Wrapper>
              <styled.Wrapper>
                <styled.SkeletonElement className="Text" />
              </styled.Wrapper>
              <styled.Wrapper>
                <styled.SkeletonWrapper>
                  <styled.SkeletonElement className="title" />
                  <styled.SkeletonSwiperWrapper>
                    {Array.from({ length: 4 }, (_, index) => (
                      <React.Fragment key={index}>
                        <styled.SkeletonElement className="swiperItem" />
                      </React.Fragment>
                    ))}
                  </styled.SkeletonSwiperWrapper>
                </styled.SkeletonWrapper>
              </styled.Wrapper>
              <styled.Wrapper>
                <styled.SkeletonWrapper>
                  <styled.SkeletonElement className="title" />
                  <styled.SkeletonSwiperWrapper>
                    {Array.from({ length: 2 }, (_, index) => (
                      <styled.SkeletonItem className="cardSwiperItem">
                        <React.Fragment key={index}>
                          <styled.SkeletonElement className="card" />
                          <styled.SkeletonElement className="info" />
                        </React.Fragment>
                      </styled.SkeletonItem>
                    ))}
                  </styled.SkeletonSwiperWrapper>
                </styled.SkeletonWrapper>
              </styled.Wrapper>
            </>
          ) : (
            <>
              <styled.Wrapper>
                <Subtitle Title=" В какой валюте ваша покупка:" />
                <styled.SwiperWrapper>
                  <Swiper spaceBetween={10} slidesPerView={"auto"}>
                    {flagData
                      .filter(
                        (currency) =>
                          currency.code === "EUR" || currency.code === "USD" || currency.code === "IDR"
                      )
                      .map((currency) => (
                        <SwiperSlide key={currency.code}>
                          <Button
                            Title={currency.title}
                            active={selectedCurrency === currency.code}
                            onClick={() => handleCurrencyClick(currency)}
                            disable={currency.disabled}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </styled.SwiperWrapper>
              </styled.Wrapper>
              <styled.Wrapper ref={amountInputRef}>
                <Subtitle Title="Цена продукта:" />
                <styled.AmountWrapper>
                  <styled.BalanceInputDisplay>
                    <BalanceInput
                      name={title}
                      subtitle={subTitle}
                      symbol={currentSymbol} 
                      value={String(amountValue)}
                      onChange={handleBalanceChange}
                      onFocus={() => handleFocus(amountInputRef)}
                    />
                  </styled.BalanceInputDisplay>
                </styled.AmountWrapper>
              </styled.Wrapper>
            </>
          )}
          <Subtitle Title={"Ссылка на товар:"} />
          <Search
            searchTerm={searchTerm}
            showClearButton={true}
            setSearchTerm={handleSearchTermChange}
            placeholder="введите URL товара"
          />
          <Subtitle Title={"Поле для комментариев"} />
          <CommentInput
            comment={comment}
            setComment={setComment}
            placeholder="Отставьте комментарий какой товар вам необходим, по какой цене, укажите все параметры товара"
          />
        <styled.Wrapper>
          <Subtitle Title={"Варианты оплат"} />
          <Swiper spaceBetween={10} slidesPerView={"auto"}>
            {currencyData
              .filter(
                (currency) =>
                  currency.code === "RUB" || currency.code === "USDT"
              )
              .map((currency) => (
                <SwiperSlide key={currency.code}>
                  <Button
                    Title={currency.title}
                    active={selectedCurrencyPay === currency.code}
                    onClick={() => handleCurrencyPayClick(currency)}
                    disable={currency.disabled}
                  />
                </SwiperSlide>
            ))}
          </Swiper>
        
        </styled.Wrapper>
        <styled.SubTitle>
          Сумма к оплате: комиссия в размере {isCommission}%
        </styled.SubTitle > 
        <AmountDisplay
          Symbol={currentPaySymbol}
          title={titlePay}
          label={"Rupiah"}
          Number={selectedCurrency!=="IDR" ? addSpaces(Math.ceil(result+((result * +isCommission)/100))) : addSpaces(Math.ceil(resultPaymentAmountIdr+((resultPaymentAmountIdr * +isCommission)/100)))}
        />
        </styled.Wrapper>
        <styled.WrapperMainButton onClick={HandleMainButton}>
          <MainButton title="Отправить заявку" />
        </styled.WrapperMainButton>
      </styled.Container>
    </>
  );
};

export default OrderProduct;
