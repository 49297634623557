import React from "react";
import * as styled from "./MoreCard.style";
import { useNavigate } from 'react-router-dom';


interface MoreCardProps {
  placeTake? : string,
  image : any,
  title : string,
  index : number,
  handleCardClick : any,
}

export const MoreCard : React.FC<MoreCardProps> = ({ image, title, index, handleCardClick, placeTake }) => {
  const navigate = useNavigate();

  const handleClick = () => {
      handleCardClick(title);
  };

  return (
    <styled.Card placeTake={placeTake} onClick={handleClick}>
      <styled.Image src={image} alt={title} />
      <styled.TitleContent>
        <styled.Title>{title}</styled.Title>
      </styled.TitleContent>
    </styled.Card>
  );
};


