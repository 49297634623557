import styled from "styled-components";
import Bg from "../../Images/BGAll.png";

export const Container = styled.div`
    width: 100%;
    background-image: url("/BGSuccess.png"); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    height: 100%;
`;