import {  useState, useCallback } from "react";

const mockFaqs = [
  { id: 1, question: "Как я могу перевести вам деньги за оплату услуг?", answer: "Мы принимаем оплату на банковские карты а так же в криптовалюте" },
  { id: 2, question: "Что если я куплю карту и не смогу оплатить нужную подписку или сервис?", answer: "Вам не за что волноваться! В случае неудачной оплаты, мы возвращаем деньги." },
  { id: 3, question: "Какие сервисы и подписки я смогу оплатить?", answer: "Мы оплачиваем 90% зарубежных сервисов.Есть небольшие исключения: Тиндер и Fansly." },
  { id: 4, question: "Безопасно ли передавать свои доступы вашему специалисту?", answer: "Да, безопасно. Мало того, вы в любой момент сможете изменить свой пароль после проведения специалистом оплаты!" },
  { id: 5, question: "Почему мой сервис стоит дороже чем обычно?", answer: "Все карты которые мы выдаем или с которых оплачиваем - Европейские.В некоторых сервисах тарифы для оплаты из стран Евросоюза отличаются от тарифов которые были в РФ до санкций." },
  { id:6, question:"Как снять деньги в банкомате без карты?", answer:"На примере PERMATA (аналогично работает в BCA, MANDIRI). Также можно снимать на кассах Indomaret. Эта функция позволяет снимать наличные в банкомате с помощью телефона, не используя пластиковую карту. Из главного меню выбираем Mobile Cash - PermataBank ATM - вводим свой номер телефона в виде 08XX-XXXX-XXXX - выбираем сумму, которую хотим получить в банкомате - проверяем всю введенную информацию и подтверждаем. Подходим к банкомату Permata - нажимаем там кнопку Mobile Cash - вводим свой номер телефона в виде 08XX-XXXX-XXXX - теперь нужно будет ввести код из SMS, которое придет на телефон - забираем деньги."}
  ];


const PAGE_LIMIT = 10;

const useFetchFaqs = () => {
  const [faqs, setFaqs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchFaqs = useCallback(async (page, searchTerm) => {
    try {
      setLoading(true);
      
      await new Promise((resolve) => setTimeout(resolve, 0));

      const startIndex = (page - 1) * PAGE_LIMIT;
      const endIndex = startIndex + PAGE_LIMIT;

      const filteredFaqs = mockFaqs.filter((faq) =>
        faq.question.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const paginatedFaqs = filteredFaqs.slice(startIndex, endIndex);

      setFaqs((prevFaqs) => (page === 1 ? paginatedFaqs : [...prevFaqs, ...paginatedFaqs]));
      setHasMore(endIndex < filteredFaqs.length);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  }, []);

  return { faqs, loading, error, hasMore, fetchFaqs };
};

export default useFetchFaqs;

