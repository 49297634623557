import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";

export const Receiving = styled.div`
    background-color: ${baseTheme.colors.main};
`;
export const Container = styled.div`
    padding: 20PX 20px 130px;
    position: relative;
`;
export const BackWrapper = styled.div`
    margin-bottom: 20px;
`;
export const Title = styled.h1`
    font-size: 25px;
    margin-bottom: 14px;
`;
export const SubTitle = styled.div`
    font-size: 12px;
    color: ${baseTheme.colors.text};
    margin-bottom: 6px;
`;
export const SubTitleInfo = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 18px 0;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
    & svg{
        width: 24px;
        height: 24px;
        margin-right: 8px;
        fill: ${baseTheme.colors.text};
    }
`;
export const SubTitleInfoWrapper = styled.div`
     margin-bottom: 20px;
`;
export const SubTitleInfoText = styled.div`
    font-size: 16px; 
    color: ${baseTheme.colors.text};
`;
export const ReceivingWrapper = styled.div`
    margin-bottom: 30px;
    & .swiper-wrapper{
        height: 42px;
    }
    & .swiper-slide{
        width: max-content;
    }
`;
export const InfoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    & svg{
        width: 20px;
        height: 20px;
        fill: ${baseTheme.colors.text};
    }
`;
export const InfoText = styled.div`
    margin-left: 20px;
`;
export const AmountWrapper = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    & svg{
        /* position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px; */
    }

`;
export const AmountWrap = styled.div`
    position: relative;
    height: 59px;
    width: 100%;
    border-radius: 10px;
    background: ${baseTheme.colors.standard};
    box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
    padding-left: 65px;
    padding-top: 5px;
    font-size: 40px;
    margin-bottom: 30px;
`;
export const Currency = styled.div`
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    font-size: 40px;
    color: ${baseTheme.colors.link};
`;
export const Input = styled.input`
    height: 59px;
    width: 270px;
    outline: none;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
    padding-left: 40px;
    font-size: 40px;
`;
export const DebitingWrapper = styled.div<{disable?: boolean}>`
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
    padding: 18px 0;
    ${(props) => props.disable && css`
        opacity:0.5;
    `}
`;
export const DebitingWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const DebitingText = styled.div`
    font-size: 16px; 
`;
export const RollerWrap = styled.div <{toggled: boolean}>`
    position: relative;
    padding: 5px;
    width: 36px;
    height: 20px;
    border-radius: 30px;
    background: #374B8E;
    ${(props) => props.toggled && css`
        background: ${baseTheme.colors.link};
        ${Roller}{
            left: calc(100% - 16px);
            transition: transform 0.3s ease;
        }
    `}
`;
export const Roller = styled.div`
    position: absolute; 
    transition: transform 0.3s ease;
    left:2px;
    top: 3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${baseTheme.colors.standard};
`;
export const MainButtonWrapper = styled.div`
`;