import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import valid from "card-validator";
import { Back } from "src/Components/Back";
import { MainButton } from "../../MainButton";
import { BalanceInput } from "../../BalanceInput";
import { Title } from "../../Title";
import { Subtitle } from "../../Subtitle";
import RateDisplay from "../../RateDisplay/RateDisplay";
import { ToastContainer, toast } from "react-toastify";

import qLogo from "../../../Svg/CardFirm/debit-card-svgrepo-com.svg";
import mastercardLogo from "../../../Svg/CardFirm/mastercard-2-logo.svg";
import maestroLogo from "../../../Svg/CardFirm/maestro.svg";
import tronLogo from "../../../Svg/CardFirm/tether-usdt-trc20.svg";
import visaLogo from "../../../Svg/CardFirm/visag.svg";
import useToastMessage from '../../../hooks/useToastMessage';
import { ReactComponent as Usdt } from "src/Svg/Flag/usdt.svg";

import * as styled from "./MoneyBox.style";

const formatCardNumber = (num) => {
  if (!num) return num;
  return num
    .toString()
    .replace(/\s?/g, "")
    .replace(/(\d{4})/g, "$1 ")
    .trim();
};

export const MoneyBox = () => {
  const [balanceValue, setBalanceValue] = useState("0");
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [goalValue, setGoalValue] = useState("");
  const [cardType, setCardType] = useState(null);
  const [isValidCard, setIsValidCard] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const showToastMessage = useToastMessage();

  const addSpaces = (num) => {
    if (!num) return num;
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleNavigate = () => {
    if (!goalValue.trim()) { 
      showToastMessage('Пожалуйста, введите данные')
    } else if (!isValidCard) {
      showToastMessage('Номер карты недействителен')
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false); 
        navigate('/страница', { state: { activeCurrency: undefined } });
      }, 2000); 
    }
  };


  const handleBalanceChange = (event) => {
    setBalanceValue(event.target.value);
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    setGoalValue("");
    setCardType(null);
    setIsValidCard(true);
  };

  const handleGoalChange = (event) => {
    setGoalValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 19) {
      const formattedValue = value.replace(/(\d{4})(?=\d)/g, "$1 ");
      setGoalValue(formattedValue);

      const cardValidation = valid.number(value);
      setIsValidCard(cardValidation.isValid);

      if (cardValidation.card) {
        setCardType(cardValidation.card.type);
      } else {
        setCardType(null);
      }
    }
  };

  const cardTypeImages = {
    visa: visaLogo,
    mastercard: mastercardLogo,
    maestro: maestroLogo,
  };

  const knownCardTypes = ["visa", "mastercard", "maestro"];

  return (
    <>
      <styled.Container>
        <styled.BackWrapper>
          <Back Title={""} continuation={true} />
        </styled.BackWrapper>
        <Title Title={"Вывод со счета"} />
        <styled.Wrapper>
          <Subtitle Title={`Сумма, которую вы хотите вывести: На счете ${addSpaces(84343)}₮`} />
          <styled.RateDisplay>
            <styled.BalanceInputDisplay>
              <BalanceInput
                symbol={Usdt}
                subtitle="TRC (20)"
                name="USDT"
                value={balanceValue}
                onChange={handleBalanceChange}
              />
            </styled.BalanceInputDisplay>
            <styled.RateDisplay>
              {/* <RateDisplay rate={addSpaces(84343)}>На счете</RateDisplay> */}
            </styled.RateDisplay>
          </styled.RateDisplay>
        </styled.Wrapper>
        <styled.Wrapper>
          <styled.Wrapper>
            <Subtitle Title={"Выберите способ:"} />
            <styled.PaymentMethod
              onClick={() => handlePaymentMethodChange("card")}
              selected={paymentMethod === "card"}
            >
              <styled.Radio selected={paymentMethod === "card"} />
              <span>Перевод на банковскую карту</span>
            </styled.PaymentMethod>
            <styled.PaymentMethod
              onClick={() => handlePaymentMethodChange("crypto")}
              selected={paymentMethod === "crypto"}
            >
              <styled.Radio selected={paymentMethod === "crypto"} />
              <span>Перевод в криптовалюту</span>
            </styled.PaymentMethod>
          </styled.Wrapper>

          {paymentMethod === "card" ? (
            <styled.InputContainer>
              {knownCardTypes.includes(cardType) ? (
                <styled.CardImage
                  src={cardTypeImages[cardType]}
                  alt={cardType}
                />
              ) : (
                <styled.CardImage src={qLogo} alt="Debit Card" />
              )}
              <styled.Input
                type="tel"
                pattern="\d*"
                value={formatCardNumber(goalValue)}
                onInput={handleInputChange}
                placeholder="введите номер карты"
              />
              {/* {!isValidCard && <p>Номер карты недействителен</p>} */}
            </styled.InputContainer>
          ) : (
            <styled.InputContainer>
              <styled.CardImage src={tronLogo} alt="TRON Wallet" />
              <styled.Input
                type="text"
                placeholder="кошелек USDT(TRC-20)"
                value={goalValue}
                onChange={handleGoalChange}
              />
            </styled.InputContainer>
          )}
        </styled.Wrapper>
        <styled.MainButtonWrapper onClick={handleNavigate}>
          <MainButton title={"Получить"} />
        </styled.MainButtonWrapper>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </styled.Container>
    </>
  );
};
