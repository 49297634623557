import React, { useState } from "react";
import * as styled from "./HistoryList.style";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Time } from "../../Svg/time.svg";
import { ReactComponent as Dollar } from "../../Svg/dollar-sign.svg";
import { ReactComponent as Repeat } from "../../Svg/repeat.svg";
import { ReactComponent as Replenishment } from "../../Svg/replenishment.svg";
import { ReactComponent as Transfer } from "../../Svg/transfer.svg";
import { ReactComponent as Mbrilogout } from "../../Svg/mbrilogout.svg";
import { ReactComponent as Order } from "../../Svg/GeneralMenu/order.svg";
import { baseTheme } from "src/Style/baseTheme";

export const HistoryList = ({ historyData }) => {
    const [isLoading, setIsLoading] = useState(true); 
    const navigate = useNavigate();
          
    const addSpaces = (num) => {
        if (!num) return num;
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
      };
      
    const handleClick = (OperationDetails) => {
        if(OperationDetails.record_type === "exchange"){
            navigate('/operation_details', { state: { OperationDetails } });
        }
        
    };

    const getIcon = (item) => {
        if (item.record_type === "inout" && item.debit === false) return <styled.ItemSvg color={`${baseTheme.colors.cardColor}`}><Mbrilogout /></styled.ItemSvg>;
        if (item.record_type === "inout") return <styled.ItemSvg  color='#EECC55'><Dollar /></styled.ItemSvg>;
        if (item.record_type === "exchange") return <styled.ItemSvg color='#55ACEE'><Repeat /></styled.ItemSvg>;
        if (item.record_type === "rent") return <styled.ItemSvg color='#FF8A71'><Replenishment /></styled.ItemSvg>;
        if (item.record_type === "transfer") return <styled.ItemSvg color='#965E91'><Transfer /></styled.ItemSvg>;
        if (item.record_type === "payfor") return <styled.ItemSvg color='#7769b4' tresh={true}><Order /></styled.ItemSvg>;
        return <Time />;
    };

    return(
        <styled.History>
            <styled.HistoryWrapper>
                {historyData?.map((item) => {
                    return(
                    <styled.HistoryItem  onClick={() => handleClick(item)} key={`${item?.order_id}-MenuItem`}>
                        <styled.ItemWrapper>
                            <styled.ItemSvg>
                                {getIcon(item)}
                                {item?.status !== true &&
                                <styled.ItemSvgPosition status={item?.status}>
                                    <Time/>
                                </styled.ItemSvgPosition>}
                            </styled.ItemSvg>
                            <styled.ItemTitleWrap>
                                <styled.ItemTitle>
                                {item?.label}
                                </styled.ItemTitle>
                                <styled.ItemData>
                                {`${item?.date ? `${item.date} ` : ''}${item?.time}`}
                                </styled.ItemData>
                            </styled.ItemTitleWrap>
                            <styled.ItemSubetitleWrap>
                                {item?.type &&
                                    <styled.ItemSubtitle>
                                        {item?.type}
                                    </styled.ItemSubtitle>
                                }
                                {item?.card_number &&
                                    <styled.ItemSubtitle>
                                        {item?.card_number}
                                    </styled.ItemSubtitle>
                                }
                            </styled.ItemSubetitleWrap>
                            <styled.ItemMoney color={item?.debit}>{item?.debit === true ? '+' : item?.debit === false ? '-' : null }{addSpaces(item?.amount)} {item?.symbol}</styled.ItemMoney>
                        </styled.ItemWrapper>
                    </styled.HistoryItem>
                )})}
            </styled.HistoryWrapper>
        </styled.History>
    );
};

