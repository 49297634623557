import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import * as styled from "./Exchange.style";
import { UrlRates } from "../../../Static/Url";
import { Back } from "src/Components/Back";
import { ExchangeCurrency } from "./ExchangeCurrency";
import { MainButton } from "../../MainButton";
import axios from "axios";
import { Link } from "react-router-dom";
import { Title } from "src/Components/Title";
import { useFetchData } from "../../../hooks/useFetchData";
import { useQuery } from "@tanstack/react-query";


export const Exchange = () => {
  const token = localStorage.getItem('authToken')
  const navigate = useNavigate();

  const {data: apiData, isLoading} = useQuery({
    queryKey:['rates'],
    queryFn:async()=>{
        const response = await axios.get(url, {
          headers: { 'Authorization': ` ${token}` }
        });
        return response.data
    },
    enabled:!!token,
    retry:5
  })
  
  const url = `${UrlRates}`;
  // const { data: apiData, isLoading } = useFetchData(url);
  console.log(apiData);
  
  const handleNavigate = () => {
    navigate('/receiving', { state: { activeCurrency: "Questions" } });
  };

    return(
    <>
    <styled.Container>
        <styled.ExchangeWrapper >
            <styled.ExchangeWrap >
              <styled.BackWrapper >
                <Back
                Title={""} 
                continuation={true}
                />
              </styled.BackWrapper >
              <Title Title={"Курсы валют"} />
              <styled.ExchangeSubitleWrapp>
                <styled.TitleWrapp>
                  <styled.Title>
                    Валюта
                  </styled.Title>
                </styled.TitleWrapp>
                <styled.TitleInDollars>
                </styled.TitleInDollars>
                <styled.TitleInDollars>
                  IDR
                </styled.TitleInDollars>
              </styled.ExchangeSubitleWrapp>
                  {isLoading ? (
                        Array.from({ length: 4 }, (_, index) => (
                        <React.Fragment key={index}>
                        <styled.SkeletonWrapperExchange>
                            <styled.SkeletonElement className="icon" />
                            <styled.SkeletonElement className="title" />
                            <styled.SkeletonElement className="subtitle" />
                            <styled.SkeletonElement className="amount" />
                        </styled.SkeletonWrapperExchange >
                        </React.Fragment>
                        ))
                    ) : (
                        apiData?.map(currency => (
                        <ExchangeCurrency key={currency.name} data={currency} />
                      ))
                    ) }
            </styled.ExchangeWrap>
            <styled.WrapperMainButton onClick={handleNavigate}>
              <MainButton title={"Начать обмен"} />
            </styled.WrapperMainButton>
        </styled.ExchangeWrapper >
    </styled.Container>
    </>
)}
