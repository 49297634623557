import styled from "styled-components";
import { baseTheme } from "../../Style/baseTheme";

export const Card = styled.div<{ placeTake?: string }>`
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 6px 16px 0px ${baseTheme.colors.boxShadow};
  overflow: hidden;
  /* height: 130px; */
  /* grid-column : 1; */
  grid-column : span 6;
  grid-row : span 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.placeTake === "2" && {
    gridColumn : "span 12",
    gridRow : "span 4",
    maxHeight : 200,
  }
}
${(props) => props.placeTake === "1" && {
    gridColumn : "span 6",
    gridRow : "span 3",
    // maxHeight : 400,
  }
}
${(props) => props.placeTake === "4" && {
    gridColumn : "span 12",
    gridRow : "span 12",

  }
}


`;

export const Image = styled.img`
  height: 60%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

export const Title = styled.h3`
  font-size: 16px;
  color: ${baseTheme.colors.bodyText};
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
`;
