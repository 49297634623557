import React from "react";
import * as styled from "./Title.style";

interface TitleProps {
    Title: string;
  }

export const Title: React.FC<TitleProps> = ({ Title }) => {

    return ( 
        <styled.TitleWrapper>
            <styled.Container>
                <styled.Title>
                    {Title}
                </styled.Title>
            </styled.Container>
        </styled.TitleWrapper>
    );
};