import React from "react";
import * as styled from "./Background.style";


export const Background = () => {

    return ( 
        <styled.Container >
          
        </styled.Container >
    );
};