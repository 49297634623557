// DebitingSection.js

import React from 'react';
import * as styled from './DebitingSection.style'; 

export const DebitingSection = ({ toggled, ...props }) => {
  return (
      <styled.DebitingWrapper disable={true}  >
          <styled.DebitingWrap>
              <styled.DebitingText>
                  Списать средства с баланса
              </styled.DebitingText>
              <styled.RollerWrap toggled={toggled}> 
                  <styled.Roller />
              </styled.RollerWrap>
          </styled.DebitingWrap>
      </styled.DebitingWrapper>
  );
};



