import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";
import Bg from "../../../Images/BGAll.png";

const loading = keyframes`
  0% {
    background-color: #eee; 
  }
  100% {
    background-color: #ddd; 
  }
`;
export const Container = styled.div`
    padding: 20PX;
    position: relative;
`;
export const HistoryWrapper = styled.div`
`;
export const HistoryWrap = styled.div`
    margin-bottom: 14px;
`;
export const HistoryTitle = styled.h1`
    margin-bottom: 14px;
`;
export const HistoryData = styled.div`
    font-size: 14px;
    color: ${baseTheme.colors.bodyText};
`;
export const HistoryBlock = styled.div`
    margin-bottom: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`;
export const SkeletonWrapper = styled.div`
    padding-top: 40px;
    position: relative;
`;

export const SkeletonElement = styled.div`
    position: absolute;
    background-color: #eee; 
    border-radius: 4px;
    animation: ${loading} 1s infinite alternate; 
  &.title {
    width: 70%;
    height: 24px;
    margin-bottom: 10px;
    top: 0;
  }

  &.listItem {
    width: 90%;
    height: 20px;
    margin-bottom: 5px;
  }
`;
export const SkeletonWrapperHistory = styled.div`
    position: relative;
    width: 100%;
    height: 64px;
    margin-bottom: 10px;
    background: ${baseTheme.colors.standard};
    border-radius: 10px;
    gap: 14px;
    padding: 15px 10px;
    box-shadow: 5px 5px 5px ${baseTheme.colors.boxShadow};
    & .iconHistory {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0;
        animation: none;
        background-color: #e0e0e0;
    }

    & .titleHistory {
        width: 70%;
        height: 19px;
        left: 52px;
    }

    & .subtitleHistory {
        width: 50%;
        height: 12px;
        bottom: 15px;
        left: 52px;
    }

    & .amountHistory {
        width: 12%;
        height: 20px;
        right: 10px;
        bottom: 10px;
    }
`;
