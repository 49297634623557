import React from "react";
import * as styled from "./Warning.style";


interface WarningProps {
    onConfirm: () => void;
    onCancel: () => void;
  }

export const Warning: React.FC<WarningProps> = ({ onConfirm, onCancel }) => {
    return (
        <styled.WarningWrapper>
            <styled.Warning>
                <styled.Text>
                    Вы уверены, что хотите выйти?
                </styled.Text>
                <styled.Button onClick={onConfirm}>Да</styled.Button>
                <styled.Button onClick={onCancel}>Отмена</styled.Button>
            </styled.Warning>
        </styled.WarningWrapper>
    );
};