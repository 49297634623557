import React from "react";
import * as styled from "./Subtitle.style";

interface TitleProps {
    Title: string;
  }

export const Subtitle: React.FC<TitleProps> = ({ Title }) => {

    return ( 
        <styled.Subtitle>
            {Title}
        </styled.Subtitle>
    );
};