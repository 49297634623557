import styled, { css, keyframes } from "styled-components";
import { baseTheme } from "../../Style/baseTheme";


export const TitleWrapper = styled.div`
`;
export const Container = styled.div`
  position: relative;
  height: 47px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & svg {
    position: absolute;
    left: 0px;
    top: 16px;
  }
`;
export const Title = styled.h1`
  font-size: 32px;
`;