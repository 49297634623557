export const PeriodData = [
  {
    day : "1 день",
    count : 1
  },
  {
    day : "3 дня",
    count : 3
  },
  {
    day : "1 неделя",
    count : 7
  },
  {
    day : "1 месяц",
    count : 14 * 4
  },
  {
    day : "2 месяца",
    count : (14 * 4) * 2
  },
  {
    day : "3 месяца",
    count : (14 * 4) * 3
  },
  ];