import React, { useState, useEffect, useRef, useCallback } from "react";
import { Back } from "src/Components/Back";
import { Title } from "../../Title/Title";
import { Search } from "../../Search/Search";
import { Accordion } from "../../Accordion/Accordion"; 
import useFetchFaqs from "./useFetchFaqs"; 
import * as styled from "./FaqPage.style";

export const FaqPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isSearchScrolled, setIsSearchScrolled] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const { faqs, loading, error, hasMore, fetchFaqs } = useFetchFaqs();

  const lastFaqElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleSearch = useCallback((term: string) => {
    setSearchTerm(term);
    setPage(1); 
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0 
    };

    const searchObserver = new IntersectionObserver(([entry]) => {
      setIsSearchScrolled(!entry.isIntersecting);
    }, observerOptions);

    if (searchRef.current) {
      searchObserver.observe(searchRef.current);
    }

    return () => {
      if (searchRef.current) {
        searchObserver.unobserve(searchRef.current);
      }
    };
  }, [searchRef]);

  useEffect(() => {
    fetchFaqs(page, searchTerm);
  }, [page, searchTerm, fetchFaqs]);

  return (
    <styled.Container>
      <styled.BackWrapper>
        <Back Title={""} continuation={true} />
      </styled.BackWrapper>
      <Title Title={"Частые вопросы"} />
      <styled.SearchWrapper searchScroll={isSearchScrolled} ref={searchRef}>
        <Search
          searchTerm={searchTerm}
          setSearchTerm={handleSearch}
          searchScroll={isSearchScrolled}
          showClearButton={true}
        />
      </styled.SearchWrapper> 
      {error && <styled.LoadingMessage>Ошибка загрузки данных</styled.LoadingMessage>}
      <styled.Accordions>
        <Accordion items={faqs} lastElementRef={lastFaqElementRef} />
      </styled.Accordions>
      {loading && (
        <styled.LoadingMessage>Загрузка...</styled.LoadingMessage>
      )}
      {hasMore && !loading && (
        <styled.LoadMoreMessage ref={lastFaqElementRef}>
          Прокрутите вниз, чтобы загрузить больше
        </styled.LoadMoreMessage>
      )}
    </styled.Container>
  );
};