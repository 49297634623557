import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";
import Bg from "../../../Images/BGAll.png";

export const Container = styled.div`
    padding: 20px 20px 130px;
    position: relative;
`;
export const Notifications = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 20px;
`;
export const Item = styled.div`
    background: ${baseTheme.colors.standard};
    border-radius: 10px;
    gap: 14px;
    padding: 20px;
    box-shadow: 15px 15px 15px ${baseTheme.colors.boxShadow};
`;
export const Main = styled.div`
    color: ${baseTheme.colors.text};
    font-size: 14px;
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
`;
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 14px;
`;
export const ItemSvg = styled.div <{tresh?:boolean, position?:boolean,color?: string}>`
    position: relative; 
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${baseTheme.colors.main};
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
        fill: ${({ tresh }) => tresh && "#fff"};
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%,50%);
    }
    & path{
        stroke: ${baseTheme.colors.standard};
    }
    ${(props) => props.color && css`
        
        background: ${props.color};
    `}
    ${(props) => props.tresh && css`
        & svg{
            stroke: ${baseTheme.colors.standard} ;
        }
    `}
`;
export const Icon= styled.div`
`;
export const Title= styled.div`
    color: ${baseTheme.colors.text};
    font-size: 18px;
`;
export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 14px 0;
    color: ${baseTheme.colors.text};
    border-bottom: 1px solid ${baseTheme.colors.borderblure};
`;
export const Text = styled.div`
    color: ${baseTheme.colors.text};
    font-size: 14px;
`;
export const Data = styled.div`
    color: ${baseTheme.colors.text};
    font-size: 14px;
    margin-top: 14px;
    display: flex;
    justify-content:space-between;
`;
export const ButtonWrapper = styled.div`
`;