import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as styled from "./Back.style";
import { ReactComponent as Chevron } from "../../Svg/chevron-left.svg";
import { ReactComponent as Close } from "../../Svg/close.svg";

interface BackProps {
  Title: string;
  continuation: boolean;
}

export const Back: React.FC<BackProps> = ({ Title, continuation }) => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  }, [navigate]);

  return ( 
    <styled.Back>
      <styled.Container>
        <styled.Icon onClick={handleGoBack}>
          {continuation ? <Chevron /> : <Close />}
        </styled.Icon>
        <styled.Title>
          {Title}
        </styled.Title>
      </styled.Container>
    </styled.Back>
  );
};